import React, { useState } from "react";
import loadable from "@loadable/component";
import styles from "./styles.css";
import PropTypes from "prop-types";
const UsedCarCategory = loadable(() => import("../used-car-category"));
import { FILTER_CATEGORY } from "../../../utils/filters-v3/constant";
import { LazyComponent } from "../../shared/lazy-image";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { sendCustomGaEvents } from "../../../tracking";
import { categoryFilterOneClick } from "../../../constants/tracking-constants/super-home-events";

const SuperWebCategoryWrapper = ({
    filterData,
    platformLabel
}) => {

    const [activeFilter, setActiveFilter] = useState(FILTER_CATEGORY.BODY_TYPE);

    const activeFilterCallback = async (active) => {
        setActiveFilter(active);

        await yieldToMain();
        sendCustomGaEvents({
            ...categoryFilterOneClick,
            eventLabel: `${(active || "").toLowerCase()} | ${platformLabel}`
        });
    };

    const currentFilterData = (filterData || []).find(f => f.category === activeFilter) || {};
    const {
        options,
        suggestions
    } = currentFilterData || {};
    const currentFilterOptions = suggestions ? suggestions : options;

    return (currentFilterOptions && currentFilterOptions.length) ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <UsedCarCategory
                    activeFilter={activeFilter}
                    currentFilterOptions={currentFilterOptions}
                    activeFilterCallback={activeFilterCallback}
                    platformLabel={platformLabel}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);

};

SuperWebCategoryWrapper.propTypes = {
    filterData: PropTypes.array,
    platformLabel: PropTypes.string
};

export default SuperWebCategoryWrapper;

