/* eslint-disable complexity */
import React, { Fragment, useRef, useEffect } from "react";
import styles from "./styles/index.css";
import PropTypes from "prop-types";
import scrollToView from "../../../utils/helpers/scroll-to-view";

const Button = (props) => {
    const {
        onClick,
        id = "",
        rupee = null,
        dataTitle = "",
        text = "",
        transparent = false,
        disabled = false,
        rightImage = null,
        leftImage = null,
        customStyle = [],
        customClass = "",
        isLoading = false,
        hideLoader = false,
        scrollTop,
        autoClick = false,
        children = null,
        isThreeDotSpinner = false,
        ...restProps
    } = props;

    const ref = useRef();

    useEffect(() => {
        if (autoClick && ref &&  ref.current) {
            ref.current.click();
        }
    }, [autoClick]);

    useEffect(() => {
        if (scrollTop) {
            window.setTimeout(() => {
                scrollToView(ref.current);
            }, 0);
        }
    }, [scrollTop]);

    let buttonStyles = [];
    customStyle.forEach(item => {
        buttonStyles.push(`styles.${item}`);
    });
    buttonStyles = buttonStyles.join(" ");
    const transparentStyle = transparent ? "styles.transparent-btn" : "";
    const disabledStyle = disabled ? "styles.disable-btn" : "";
    //eslint-disable-next-line
    const styleName = `styles.common-btn styles.btn-styles ${disabledStyle} ${transparentStyle} ${buttonStyles}`;
    const textNotVisibleOnLoding = isThreeDotSpinner && isLoading;
    return (
        <Fragment>
            <button ref={ref} id={id} data-title={dataTitle}
                disabled={disabled || isLoading} onClick={onClick}
                type="button" className={`btn ${customClass}`}
                styleName={styleName}
                // styleName={`styles.common-btn styles.btn-styles ${disabled ? "styles.disable-btn" : ""}`}
                {...restProps}
            >
                {!(textNotVisibleOnLoding) && <React.Fragment>
                    {leftImage && <img styleName={"styles.img-left"} src={leftImage} alt="left arrow" />}
                    {text} {rightImage && <img styleName={"styles.img-right"} src={rightImage} alt="right arrow"/>}
                    {rupee && <span>{rupee}</span>}
                </React.Fragment>}
                {
                    isLoading && !hideLoader && !isThreeDotSpinner &&
                    <i className="spinner" />
                }
                {
                    children && children
                }
            </button>
        </Fragment>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string,
    rupee: PropTypes.any,
    dataTitle: PropTypes.string,
    text: PropTypes.string,
    transparent: PropTypes.bool,
    disabled: PropTypes.bool,
    rightImage: PropTypes.any,
    leftImage: PropTypes.any,
    customStyle: PropTypes.array,
    customClass: PropTypes.string,
    isLoading: PropTypes.bool,
    scrollTop: PropTypes.bool,
    hideLoader: PropTypes.bool,
    autoClick: PropTypes.bool,
    isThreeDotSpinner: PropTypes.bool,
    children: PropTypes.any
};

export default Button;
