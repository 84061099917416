/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), { ssr: true });

import PropTypes from "prop-types";
import HeartFilled from "./images/icon-heart-filled.svg";
import HeartEmpty from "./images/icon-heart-empty.svg";
import likeExperiment from "./images/like-experiment.json";
import dislikeExperiment from "./images/dislike-experiment.json";
import pumpingExperiment from "./images/pumping-experiment.json";
import WishistHeartFilled from "./images/wishist-icon-active.svg";
import WishistHeartEmpty from "./images/wishist-icon.svg";
import { NUMBER } from "../../../constants/app-constants";

const ICON_SOURCE = {
    "default": { active: HeartFilled, inActive: HeartEmpty },
    "header": { active: WishistHeartFilled, inActive: WishistHeartEmpty }
};

const IconHeart = ({
    active,
    onClick = () => { },
    source = "default",
    isCarDetailPage = false
}) => {
    const ICON_SOURCE_DETAIL = {
        "like": likeExperiment,
        "dislike": dislikeExperiment,
        "pumping": pumpingExperiment
    };

    const [dislikeClicked, setDislikeClicked] = useState(false);

    const defaultOptions = {
        loop: (active || dislikeClicked) ? false : NUMBER.ONE,
        autoplay: true,
        animationData: active ? ICON_SOURCE_DETAIL.like : dislikeClicked ? ICON_SOURCE_DETAIL.dislike : ICON_SOURCE_DETAIL.pumping,
        animType: "svg",
        renderer: "svg",
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const onClickHandler = (e) => {
        if (active) {
            setDislikeClicked(true);
        }
        onClick(e);
    };
    const renderHeartIcon = () => {
        if (isCarDetailPage) {
            return (
                <Lottie width={24} height={24} options={defaultOptions}/>);

        }
        return (< img src={
            active ? ICON_SOURCE[source].active : ICON_SOURCE[source].inActive
        }
        alt={`${active ? "Remove from" : "Add to"} wishlist`
        }
        width={24} height={24} />);
    };
    return (
        <div styleName={"styles.outer"}>
            <button onClick={onClickHandler}>
                {renderHeartIcon()}
            </button>
        </div>
    );
};

IconHeart.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    source: PropTypes.string,
    isCarDetailPage: PropTypes.bool
};
export default IconHeart;
