import React from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { DOWNLOAD_BANNER_TYPES, MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import parseDownloadAppSection from "../../../utils/helpers/parse-download-app-section";
import { LazyComponent } from "../../shared/lazy-image";
import { InView } from "react-intersection-observer";
import { sendCustomGaEvents } from "../../../tracking";
import { downloadBannerView } from "../../../constants/tracking-constants/super-home-events";
const SuperWebDownloadAppSection = loadable(() => import("../super-web-download-app-section"));

const SuperWebDownloadAppWrapper = ({
    superApp,
    sectionKey,
    platformLabel
}) => {
    const { parsedData} = superApp || {};
    const {
        meta: metaDescription,
        data
    } = parsedData[sectionKey] || {};
    const {
        content
    } = data || {};

    const downloadData = parseDownloadAppSection(content, MEDIA_DEVICE_TYPES.DESKTOP) || {};

    const onChangeViewHandler = (inView) => {
        if (inView) {
            sendCustomGaEvents({
                ...downloadBannerView,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.DOWNLOAD} | ${platformLabel}`
            });
        }
    };

    return downloadData && Object.keys(downloadData).length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <InView threshold={0.9} onChange={(inView) => onChangeViewHandler(inView)}>
                    <SuperWebDownloadAppSection
                        metaDescription={metaDescription}
                        downloadData={downloadData}
                        platformLabel={platformLabel}
                    />
                </InView>

            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebDownloadAppWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    platformLabel: PropTypes.string
};

export default SuperWebDownloadAppWrapper;
