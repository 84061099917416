import variationPicker from "./variation-picker";

const variations = {
    "home-5": {
        filterUsefulLinks: links => links.filter(({ url }) => !url.match(/buy-used-cars/g))
    },
    default: {
        filterUsefulLinks: links => links
    }
};

export const footerVariation = (type) => variationPicker(type, variations);
