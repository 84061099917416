import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import LazyImage from "../lazy-image";

const BrandIcon = ({ image, altText, isLazyLoad = true }) => {
    //data-title represents the data to be pushed into ga
    //if you are changing alt text parameter, ensure
    //data-title is in sync with requirement
    if (!isLazyLoad) {
        return (<img
            data-title={altText}
            className={"img-fluid"}
            styleName={"styles.image"}
            src={image}
            alt={altText}
            // fetchpriority="high"
        />);
    }

    return (<LazyImage
        data-title={altText}
        className={"img-fluid"}
        styleName={"styles.image"}
        src={image}
        alt={altText} />);
};

BrandIcon.propTypes = {
    image: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    isLazyLoad: PropTypes.bool
};

export default BrandIcon;

