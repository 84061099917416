import React, { useMemo } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { SwiperSlider } from "../../shared/swiper";
import getFilteredMediaData from "../../../utils/helpers/get-filtered-media-data";
import { MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import { InfoCard } from "@cars24/turbo-web/lib/info-card";
import SuffixIcon from "./suffix-icon";
import { LazyComponent } from "../../shared/lazy-image";
import { InView } from "react-intersection-observer";
import { sendCustomGaEvents } from "../../../tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { ourServiceViewed, ourServiceClick } from "../../../constants/tracking-constants/super-home-events";
const SuperWebOurServicesSection = loadable(() => import("../super-web-our-services-section"));

const SuperWebOurServicesWrapper = ({
    superApp,
    sectionKey,
    onClickCustomHandler = () => {},
    platformLabel,
    pincode
}) => {
    const { parsedData } = superApp || {};
    const { title, data: sectionData } = parsedData[sectionKey] || {};
    const { content } = sectionData || {};

    const onBannerClickHandler = async (params, label) => {
        onClickCustomHandler(params);

        await yieldToMain();
        sendCustomGaEvents({
            ...ourServiceClick,
            eventLabel: `${label} | ${platformLabel}`
        });
    };

    const onChangeViewHandler = async (inView, label) => {
        if (inView) {
            await yieldToMain();
            sendCustomGaEvents({
                ...ourServiceViewed,
                eventLabel: `${label} | ${platformLabel}`
            });
        }
    };

    const bannerData = useMemo(() => {

        const filteredBannerData = getFilteredMediaData(content, MEDIA_DEVICE_TYPES.DESKTOP);
        // eslint-disable-next-line consistent-return
        const bannersList = filteredBannerData.map((bannerItem) => {

            const { key, media, action } = bannerItem || {};
            const { url: bannerUrl, meta: mediaMetaData } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { heading, content: bannerMetaContent, ctaText, flowType } = mediaMetaData || {};

            return (
                <SwiperSlider key={key}>
                    <InView threshold={1} onChange={(inView) => onChangeViewHandler(inView, heading)}>
                        <InfoCard
                            imageUrl={bannerUrl}
                            title={heading}
                            content={bannerMetaContent}
                            buttonLabel={ctaText}
                            btnClickHandler={onBannerClickHandler.bind(null, {action, media, flowType}, heading)}
                            suffixIcon={<SuffixIcon />}
                            withButton={true}
                        />
                    </InView>
                </SwiperSlider>
            );

        });

        return bannersList || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, pincode]);

    return bannerData && bannerData.length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <SuperWebOurServicesSection
                    bannerData={bannerData}
                    title={title}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebOurServicesWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    onClickCustomHandler: PropTypes.func,
    platformLabel: PropTypes.string,
    pincode: PropTypes.string
};

export default SuperWebOurServicesWrapper;
