import React from "react";
import styles from "./styles.css";
import ChatIcon from "./images/help-icon.svg";
import PropTypes from "prop-types";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { BodyText } from "@cars24/turbo-web/lib/body-text";

const ChatWidget = ({
    onClick = () => {}
}) => {

    const { tokens } = useTokens();

    return (
        <div styleName={"styles.chatRevampContainer"} onClick={onClick}>
            <div styleName={"styles.chatContainer"}>
                <img src={ChatIcon} alt={"need help"}/>

                <div styleName={"styles.needHelpText"}>
                    <BodyText isBold color={tokens.text_default_text_inverse} level={3}>Need Help ?</BodyText>
                </div>

                <div styleName={"styles.helpText"}>
                    <BodyText isBold color={tokens.text_default_text_inverse} level={3}>Help</BodyText>
                </div>

            </div>
        </div>
    );
};

export default ChatWidget;

ChatWidget.propTypes = {
    onClick: PropTypes.func.isRequired
};
