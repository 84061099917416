import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchWishlistCarsId as fetchWishlistCarsIdConnect,
    fetchWishlistedCars as fetchWishlistedCarsConnect
} from "../components/desktop/wishlist/actions";
import { CF_STATUS } from "../constants/cf-constants";
import { getCarListPayload } from "../utils/helpers/get-pa-offer-terms";

const useWishlistDesktop = (needCarContent = true) => {

    const { user, wishlist, consumerFinance } = useSelector(store => store);
    const dispatch = useDispatch();
    const { isLoggedIn, mobile, gaId: clientId, userId, preApproved, preApprovalScreenVariant, secureToken } = user || {};
    const { cfStatus, selectedOffer } = consumerFinance || {};
    const { flattenedData } = wishlist || {};
    const wishlistCarCount = (flattenedData || []).length;

    useEffect(() => {
        if (isLoggedIn && mobile) {
            dispatch(fetchWishlistCarsIdConnect({
                userId,
                mobile,
                clientId,
                token: secureToken
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, mobile]);

    useEffect(() => {
        if (isLoggedIn && mobile && needCarContent) {
            const appIds = (flattenedData || []).join(",");
            dispatch(fetchWishlistedCarsConnect({
                userId,
                clientId,
                params: {
                    appIds,
                    ...(preApproved && cfStatus !== CF_STATUS.DISCARDED && { ...getCarListPayload(preApprovalScreenVariant, selectedOffer) })
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, mobile, wishlistCarCount, preApproved]);

};

export default useWishlistDesktop;
