import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { fetchPresetFilters } from "../buy-used-car/actions";
import { reloadCarList, getSearchSuggestions } from "../car-list/actions";
import { fetchFilters, resetAppliedFilters } from "../filter-v2/action";
import FindDreamCarsV2 from "./component";

const mapStateToProps = ({
    cities: { selectedCity },
    buyUsedCarHome: { popularCars },
    location: { pincode, cityInfo: { city_id: cityId } },
    filters: {
        selectedFilters
    },
    user: {
        isLoggedIn,
        mobile
    }
}) => ({
    selectedCity,
    popularCars,
    pincode,
    selectedFilters,
    isLoggedIn,
    mobile,
    cityId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarSearchConnect: getSearchSuggestions,
    fetchPresetFiltersConnect: fetchPresetFilters,
    reloadCarListConnect: reloadCarList,
    fetchFiltersConnect: fetchFilters,
    resetAppliedFiltersConnect: resetAppliedFilters
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FindDreamCarsV2));
