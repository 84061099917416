export default ({ action, media }) => {
    const { POPUP_IMAGE: popupImageActionData } = action || {};
    const { POPUP_IMAGE: popupImageData, POPUP_LOGO: popupLogoData } =
    media || {};
    const { url: popupLandingImgUrl, meta: popupImageMeta } =
    popupImageData || {};
    const { url, popUpDetails, meta: popupActionMeta } = popupImageActionData || {};
    const { url: popupIcon, meta: popupLogoMeta } = popupLogoData || {};
    const data = {
        popupLandingImgUrl,
        popUpDetails,
        redirectUrl: url,
        popupIcon,
        ...popupImageMeta,
        ...popupLogoMeta,
        ...popupActionMeta
    };
    return data;
};
