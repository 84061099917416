import { useEffect } from "react";

const useBlockScroll = (isBlocked) => {
    useEffect(() => {
        if (isBlocked) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isBlocked]);
};

export default useBlockScroll;
