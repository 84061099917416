import Types from "./types";
import { UsedCarService } from "../../service/used-car-service";

const fetchFiltersInit = () => ({
    type: Types.FETCH_FILTERS
});

const fetchFilterSuccess = (data, applySuggestion = false) => ({
    type: Types.FETCH_FILTERS_SUCCESS,
    data,
    applySuggestion
});

const fetchFiltersFailure = () => ({
    type: Types.FETCH_FILTERS_FAILURE
});

const fetchFilters = (params) => (dispatch) => {
    dispatch(fetchFiltersInit());
    UsedCarService.fetchDynamicFilters(params)
        .then((response) => {
            dispatch(fetchFilterSuccess(response.data.data));
        })
        .catch(() => {
            dispatch(fetchFiltersFailure());
        });
};

const fetchFilterV2 = (params) => (dispatch) => {
    dispatch(fetchFiltersInit());
    UsedCarService.fetchDynamicFilterV2(params)
        .then((response) => {
            dispatch(fetchFilterSuccess(response.data.data.filters));
        })
        .catch(() => {
            dispatch(fetchFiltersFailure());
        });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updateFilterTimestamp = (lastUpdated) => ({
    type: Types.UPDATE_FILTER_TIMESTAMP,
    lastUpdated
});

const updateSelectedOnScreenFilter = (selectedOnScreenFilter) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    selectedOnScreenFilter
});

const updateAllFiltersVisibility = (showAllFilters) => ({
    type: Types.UPDATE_ALL_FILTERS_VISIBILITY,
    showAllFilters
});

const updateFilter = (data) => {
    return {
        type: Types.UPDATE_FILTER,
        ...data
    };
};

const updateSelectedFilter = (data) => {
    return {
        type: Types.UPDATE_SELECTED_FILTER,
        carListFilter: data
    };
};

const updateFilterTabType = (activeFilterTab) => ({
    type: Types.UPDATE_FILTER_TAB_TYPE,
    activeFilterTab
});

const showFiltersOnModal = (filtersOnModal) => ({
    type: Types.SHOW_FILTER_ON_MODAL,
    filtersOnModal
});

const updateSelectedSuggestions = (data) => ({
    type: Types.UPDATE_SELECTED_SUGGESTIONS,
    data
});

const resetFilters = () => ({
    type: Types.RESET_FILTERS
});

const resetCurrentFilters = (key) => ({
    type: Types.RESET_CURRENT_FILTER,
    key
});

const clearSuggestion = (key) => ({
    type: Types.CLEAR_SUGGESTION,
    key
});

const updatePriceFilterTab = (activePriceTab) => ({
    type: Types.UPDATE_PRICE_FILTER_TAB,
    activePriceTab
});

const clearAllModal = (data) => ({
    type: Types.CLEAR_ALL_MODAL,
    data
});

const clearCurrentFilter = (key) => ({
    type: Types.RESET_CURRENT_FILTER,
    key
});

const setIsIndiaPage = (isIndiaPage = true) => ({
    type: Types.SET_INDIA_PAGE,
    isIndiaPage
});

const setIndiaPageUrl = (indiaPageUrl = "") => ({
    type: Types.SET_INDIA_PAGE_URL,
    indiaPageUrl
});

const setFilterSearchQuery = (filterSearchQuery) => ({
    type: Types.SET_FILTER_SEARCH_QUERY,
    filterSearchQuery
});

export {
    fetchFilters,
    fetchFilterV2,
    updateSSRStatus,
    updateFilterTimestamp,
    updateSelectedOnScreenFilter,
    updateAllFiltersVisibility,
    updateFilter,
    updateFilterTabType,
    showFiltersOnModal,
    updateSelectedSuggestions,
    resetFilters,
    resetCurrentFilters,
    clearSuggestion,
    updatePriceFilterTab,
    clearAllModal,
    clearCurrentFilter,
    updateSelectedFilter,
    setIsIndiaPage,
    setIndiaPageUrl,
    setFilterSearchQuery
};
