export const sellCarLinks = [
    {
        title: "Delhi",
        seoLink: "sell-used-car-new-delhi/"
    },
    {
        title: "Mumbai",
        seoLink: "sell-used-car-mumbai/"
    },
    {
        title: "Bangalore",
        seoLink: "sell-used-car-bangalore/"
    },
    {
        title: "Hyderabad",
        seoLink: "sell-used-car-hyderabad/"
    },
    {
        title: "Ahmedabad",
        seoLink: "sell-used-car-ahmedabad/"
    },
    {
        title: "Gurgaon",
        seoLink: "sell-used-car-gurgaon/"
    },
    {
        title: "Chennai",
        seoLink: "sell-used-car-chennai/"
    },
    {
        title: "Pune",
        seoLink: "sell-used-car-pune/"
    },
    {
        title: "Noida",
        seoLink: "sell-used-car-noida/"
    },
    {
        title: "Ghaziabad",
        seoLink: "sell-used-car-ghaziabad/"
    },
    {
        title: "Lucknow",
        seoLink: "sell-used-car-lucknow/"
    },
    {
        title: "Jaipur",
        seoLink: "sell-used-car-jaipur/"
    },
    {
        title: "Kolkata",
        seoLink: "sell-used-car-kolkata/"
    },
    {
        title: "Kochi",
        seoLink: "sell-used-car-kochi/"
    },
    {
        title: "Indore",
        seoLink: "sell-used-car-indore/"
    }
];
