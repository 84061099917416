import React from "react";
import PropTypes from "prop-types";
import { ServiceWrapper } from "@cars24/turbo-web/lib/service-wrapper";

const AllServices = ({
    serviceList = [],
    onClickServiceTab = () => {}
}) => {
    return (
        <ServiceWrapper list={serviceList} withFixedWidth={false} onClick={onClickServiceTab}/>
    );
};

AllServices.propTypes = {
    serviceList: PropTypes.array,
    onClickServiceTab: PropTypes.func
};

export default AllServices;
