import { CAR_DISPOSITION_STATUS, DISPOSITION_STATUS_MAP, NUMBER } from "../../constants/app-constants";
import { DEFAULT_MAX_DISBURSAL_LTV } from "../../constants/cf-constants";
import toFixedCustom from "./to-fixed-custom";

export const getDownPayment = (preApprovalScreenVariant = "B", ltvValue = DEFAULT_MAX_DISBURSAL_LTV) => {
    const dpCardValue = Math.round((NUMBER.ONE - ltvValue) * NUMBER.HUNDRED);
    if (preApprovalScreenVariant === "C") {
        return dpCardValue > NUMBER.ZERO ? `${dpCardValue}%` : "Zero";
    }
    return "Zero";
};

export const getInterestRate = (preApprovalScreenVariant = "B", interestRate) => {
    if (preApprovalScreenVariant === "C") {
        return interestRate || NUMBER.TWELVE;
    }
    return NUMBER.TWELVE;
};

export const getCarListPayload = (preApprovalScreenVariant = "B", selectedOffer = {}) => {

    const { maxDisbursalLtv, tenure: tenureValue, interestRate } = selectedOffer || {};
    const ltvValue = maxDisbursalLtv || DEFAULT_MAX_DISBURSAL_LTV;

    const percentageDpValue = preApprovalScreenVariant === "C" ? (NUMBER.ONE - ltvValue) : NUMBER.ZERO;
    const percentageDp = toFixedCustom(percentageDpValue, NUMBER.TWO);
    const roi = getInterestRate(preApprovalScreenVariant, interestRate);

    return {
        percentageDp,
        roi,
        tenure: tenureValue || NUMBER.SEVENTY_TWO
    };
};

export const checkCibilSoftPull = (cibilSoftPull) => cibilSoftPull && cibilSoftPull === "A";

export const getPayloadForCarCompare = (car) => {
    const {city,
        appointmentId,
        imageUrl,
        make,
        model,
        variant,
        year,
        baseUrl,
        status
    } = car || {};
    const carName = `${make}${model}`;
    const reserved = status === CAR_DISPOSITION_STATUS.RESERVED;
    return {
        city,
        carId: appointmentId,
        mainImage: { url: imageUrl },
        make,
        model,
        variant,
        year,
        baseUrl,
        carName,
        reserved,
        dispositionStatus: DISPOSITION_STATUS_MAP[status]
    };
};
