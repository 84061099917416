import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SuperWebWhyUsWrapper from "./component";

const mapStateToProps = ({
    superApp,
    user: {
        preApproved
    },
    consumerFinance: {
        selectedOffer,
        name
    },
    location: {
        pincode
    }
}) => ({
    superApp,
    preApproved,
    selectedOffer,
    cfUserName: name,
    pincode
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuperWebWhyUsWrapper);
