import { CatalogGatewayService } from "../../../service/catalog-gateway";
import Types from "./types";

const fetchWishlistedCarsInit = () => ({
    type: Types.FETCH_WISHLISTED_CARS
});

const fetchWishlistedCarsSuccess = (data) => ({
    type: Types.FETCH_WISHLISTED_CARS_SUCCESS,
    data
});

const fetchWishlistedCarsFailure = (error) => ({ type: Types.FETCH_WISHLISTED_CARS_FAILURE, error });

const addToWishlistInit = (carId) => ({
    type: Types.ADD_TO_WISHLIST,
    carId
});

const addToWishlistSuccess = (carId) => ({
    type: Types.ADD_TO_WISHLIST_SUCCESS,
    carId
});

const addToWishlistCarID = (data) => ({
    type: Types.ADD_TO_WISHLIST_CAR_ID,
    data
});

const addToWishlistFailure = (error) => ({ type: Types.ADD_TO_WISHLIST_FAILURE, error });

const addToWishlist = (data, params, token) => dispatch => {
    dispatch(addToWishlistInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.addToWishlist(data, params, token)
            .then(response => {
                dispatch(addToWishlistSuccess((data || {}).appointmentId));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(addToWishlistFailure(error));
                reject(error);
            });
    });

};

const removeFromWishListInit = (carId) => ({
    type: Types.REMOVE_FROM_WISHLIST,
    carId
});

const removeFromWishListSuccess = (carId) => ({
    type: Types.REMOVE_FROM_WISHLIST_SUCCESS,
    carId
});

const removeFromWishlistCarID = (data) => ({
    type: Types.REMOVE_FROM_WISHLIST_CAR_ID,
    data
});

const removeFromWishListFailure = (error) => ({ type: Types.REMOVE_FROM_WISHLIST_FAILURE, error });

const removeFromWishList = (params, token) => dispatch => {
    dispatch(removeFromWishListInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.addToWishlist(params, {}, token)
            .then(response => {
                dispatch(removeFromWishListSuccess(params.appointmentId));
                dispatch(removeFromWishlistCarID(params.appointmentId));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(removeFromWishListFailure(error));
                reject(error);
            });
    });
};

const setWishlistAppIds = (data) => ({
    type: Types.SET_WISHLIST_APP_IDS,
    data
});

const fetchWishlistCarsId = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.fetchWishlistedCarsIds(payload).then((response) => {
            const { data: responseData } = response || {};
            const { appIds } = responseData || {};
            dispatch(setWishlistAppIds(appIds));
            resolve(appIds);
        }).catch((error) => {
            reject(error);
        });
    });
};

const fetchWishlistedCars = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchWishlistedCarsInit());
        CatalogGatewayService.fetchWishlistedCarsData(payload).then((response) => {
            const { data } = response || {};
            dispatch(fetchWishlistedCarsSuccess(data));
            resolve(data);
        }).catch((error) => {
            dispatch(fetchWishlistedCarsFailure(error));
            reject(error);
        });
    });
};

export {
    fetchWishlistedCars,
    addToWishlist,
    removeFromWishList,
    addToWishlistCarID,
    removeFromWishlistCarID,
    fetchWishlistCarsId
};
