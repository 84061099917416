import Types from "./types";
import { CouponService } from "../../../service/coupon-service-api";
import { CatalogGatewayService } from "../../../service/catalog-gateway";
import { VehicleService } from "../../../service/vehicle-service";
import { BUY_USED_CAR_HOME_TEMPLATES } from "../../../constants/buy-used-car-home-constants";
import { CmsServiceApi } from "../../../service/cms-service";

const fetchedcarModelsByBrands = () => ({
    type: Types.FETCH_BUY_USED_CAR_MODELS_BY_BRANDS
});

const fetchBuyUsedCarModelsByBrandsSuccess = (data) => ({
    type: Types.FETCH_BUY_USED_CAR_MODELS_BY_BRANDS_SUCCESS,
    data
});

const updateCarDetailsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const fetchBuyUsedCarModelsByBrandsFailure = (error) => ({ type: Types.FETCH_BUY_USED_CAR_MODELS_BY_BRANDS_FAILURE, error });

const fetchBuyUsedCarModelsByBrands = () => (dispatch) => {
    dispatch(fetchedcarModelsByBrands());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.fetchBuyUsedCarModelsByBrands()
            .then(response => {
                dispatch(fetchBuyUsedCarModelsByBrandsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchBuyUsedCarModelsByBrandsFailure(error));
                reject(error);
            });
    });
};

const fetchPresetFiltersSuccess = (data) => ({
    type: Types.FETCH_PRESET_FILTERS_SUCCESS,
    data
});

const fetchPresetFilters = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getPresetFilters(params)
            .then(response => {
                dispatch(fetchPresetFiltersSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchRecentlyCarsSuccess = (data) => ({
    type: Types.FETCH_RECENT_CARS_LIST_SUCCESS,
    data
});

const fetchRecentlyCarsInit = () => ({
    type: Types.FETCH_RECENT_CARS_LIST
});

const fetchRecentlyCarsFailure = (error) => ({ type: Types.FETCH_RECENT_CARS_LIST_FAILURE, error });

const fetchRecentCarsList = (payload) => (dispatch) => {
    dispatch(fetchRecentlyCarsInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(payload)
            .then(response => {
                dispatch(fetchRecentlyCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchRecentlyCarsFailure(error));
                reject(error);
            });
    });
};

const fetchBankOfferCouponsSuccess = (offers) => ({
    type: Types.FETCH_BANK_OFFER_COUPONS_SUCCESS,
    offers
});

const fetchBankOfferCouponsFailure = (error) => ({
    type: Types.FETCH_BANK_OFFER_COUPONS_FAILURE,
    error
});

const fetchBankOfferCoupons = ({ clientId, secureToken }) => dispatch => {
    return new Promise((resolve, reject) => {
        CouponService.fetchBankOfferCoupons(clientId, secureToken)
            .then(response => {
                dispatch(fetchBankOfferCouponsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchBankOfferCouponsFailure(error));
                reject(error);
            });
    });
};

const setShowSelectBankOffer = (showSelectedBankOfferData) => ({
    type: Types.SET_SHOW_SELECTED_BANK_OFFER,
    showSelectedBankOfferData
});

const setShowPopularCitiesOnHomepage = (value) => ({
    type: Types.SET_SHOW_POPULAR_CITIES_ON_HOMEPAGE,
    value
});

const fetchMotivationalStoriesSuccess = (data) => ({
    type: Types.FETCH_MOTIVATIONAL_STORIES_SUCCESS,
    data
});

const fetchMotivationalStoriesFailure = (error) => ({
    type: Types.FETCH_MOTIVATIONAL_STORIES_FAILURE,
    error
});

const fetchMotivationalStories = () => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.fetchMotivationalStories()
            .then(response => {
                dispatch(fetchMotivationalStoriesSuccess(response.data ? response.data.detail : {}));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchMotivationalStoriesFailure(error));
                reject(error);
            });
    });
};

const fetchHomePageBannersSuccess = (data) => ({
    type: Types.FETCH_HOME_PAGE_BANNERS_SUCCESS,
    data
});

const fetchHomePageBannersInit = () => ({
    type: Types.FETCH_HOME_PAGE_BANNERS
});

const fetchHomePageBannersFailure = (error) => ({ type: Types.FETCH_HOME_PAGE_BANNERS_FAILURE, error });

const fetchHomePageBanners = (params) => (dispatch) => {
    dispatch(fetchHomePageBannersInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.fetchHomePageBanners(params)
            .then(response => {
                dispatch(fetchHomePageBannersSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchHomePageBannersFailure(error));
                reject(error);
            });
    });
};

const updateHomePageSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});
const fetchBankOfferCouponsById = (offerId) => () => {
    return new Promise((resolve, reject) => {
        CouponService.fetchBankOfferCouponsById(offerId)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const resetPopularLocation = () => ({
    type: Types.RESET_POPULAR_LOCATIONS
});

const updateHomePageBannersSSR = (isSSR) => ({
    type: Types.UPDATE_HOME_PAGE_BANNERS_SSR,
    isSSR
});

const fetchBuyUsedCarHomeTemplate = () => ({
    type: Types.FETCH_BUY_USED_CAR_HOME_TEMPLATE
});

const fetchBuyUsedCarHomeTemplateSuccess = (data) => ({
    type: Types.FETCH_BUY_USED_CAR_HOME_TEMPLATE_SUCCESS,
    data
});

const fetchBuyUsedCarHomeTemplateFailure = () => ({
    type: Types.FETCH_BUY_USED_CAR_HOME_TEMPLATE_FAILURE
});

const updateUsedCarHomeTemplateSSRStatus = (flag) => ({
    type: Types.UPDATE_SSR,
    flag
});

const fetchBuyUsedCarsHomeTemplateData = (token, params) => (dispatch) => {
    dispatch(fetchBuyUsedCarHomeTemplate());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCMSPageTemplates(token, params, BUY_USED_CAR_HOME_TEMPLATES.USED_CAR_INDIA_HOMEPAGE_DESKTOP)
            .then(res => {
                const {data}  = res?.data || {};
                const {data: templateData} = data || {};
                dispatch(fetchBuyUsedCarHomeTemplateSuccess(templateData || []));
                resolve(data);
            }).catch((e) => {
                dispatch(fetchBuyUsedCarHomeTemplateFailure());
                reject(e);
            });
    });
};

const setBannerPopupContent = (data) => ({
    type: Types.SET_BANNER_POPUP_CONTENT,
    data
});

const setBuyUsedHomeCMSData = (data) => ({
    type: Types.SET_CMS_DATA,
    data
});

const setCmsDataError = () => ({
    type: Types.SET_CMS_DATA_ERROR
});

const fetchCmsSeoDataForHome = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CmsServiceApi.getCmsData(data)
            .then(response => {
                dispatch(setBuyUsedHomeCMSData(response.data.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(setCmsDataError());
                reject(error);
            });
    });
};

export {
    fetchBuyUsedCarModelsByBrands,
    updateCarDetailsSSRStatus,
    fetchPresetFilters,
    fetchRecentCarsList,
    fetchBankOfferCoupons,
    setShowSelectBankOffer,
    setShowPopularCitiesOnHomepage,
    fetchMotivationalStories,
    fetchHomePageBanners,
    updateHomePageSSRStatus,
    fetchBankOfferCouponsById,
    resetPopularLocation,
    updateHomePageBannersSSR,
    fetchPresetFiltersSuccess,
    fetchBuyUsedCarsHomeTemplateData,
    updateUsedCarHomeTemplateSSRStatus,
    setBannerPopupContent,
    setBuyUsedHomeCMSData,
    fetchCmsSeoDataForHome
};
