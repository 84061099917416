import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_FEATURED_CARS
    FETCH_FEATURED_CARS_SUCCESS
    FETCH_FEATURED_CARS_FAILURE
`,
    {
        prefix: "featuredcars/"
    }
);
