import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { NUMBER } from "../../../constants/app-constants";
const COUNTDOWN_INTERVAL = 1000;

const CountdownTimer = ({
    interval,
    onComplete,
    onlyText = false,
    showZero = false
}) => {
    const [countdownInterval, setCountdownInterval] = useState(interval);
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = window.setInterval(() => {
            setCountdownInterval(c => {
                if (c === 1) {
                    window.clearInterval(intervalRef.current);
                    onComplete();
                }
                return c - 1;
            });
        }, COUNTDOWN_INTERVAL);
        return () => window.clearInterval(intervalRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !onlyText ? (<span styleName={"styles.spinnerWrap"}>
        <i
            className="spinner spinnerOrange"
            styleName={"styles.otpSpinner"} />
        {countdownInterval}
    </span>) :  `${(showZero && countdownInterval < NUMBER.TEN) ? "0" : ""}${countdownInterval}`;
};

CountdownTimer.propTypes = {
    interval: PropTypes.number,
    onComplete: PropTypes.func,
    onlyText: PropTypes.bool,
    showZero: PropTypes.bool
};

export default CountdownTimer;
