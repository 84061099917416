
import { reducerConstants } from "../../../constants/reducer-constant";
import { INITIAL_STATE as buyerTestimonialsReducerInitialState, HANDLERS as buyerTestimonialsReducerHandler } from "../../../components/desktop/testimonials-buy-car/reducers";
import { INITIAL_STATE as buyUsedCarHomeReducerInitialState, HANDLERS as buyUsedCarHomeReducerHandler } from "../../../components/desktop/buy-used-car/reducers";
import { INITIAL_STATE as nearbyCitiesReducerInitialState, HANDLERS as nearbyCitiesReducerHandler } from "../../../components/desktop/nearby-cities/reducers";
import { INITIAL_STATE as featuredCarsReducerInitialState, HANDLERS as featuredCarsReducerHandler } from "../../../components/desktop/featured-cars-home/reducers";
import { INITIAL_STATE as superAppInitialState, HANDLERS as superAppHandlers } from "../../../components/desktop/super-app/reducers";

export default {
    [reducerConstants.BUY_CAR_TESTIMONIAL]: { state: buyerTestimonialsReducerInitialState, handler: buyerTestimonialsReducerHandler },
    [reducerConstants.BUY_USED_CAR_HOME]: { state: buyUsedCarHomeReducerInitialState, handler: buyUsedCarHomeReducerHandler },
    [reducerConstants.NEARBY_CITIES]: { state: nearbyCitiesReducerInitialState, handler: nearbyCitiesReducerHandler },
    [reducerConstants.FEATURED_CARS]: { state: featuredCarsReducerInitialState, handler: featuredCarsReducerHandler },
    [reducerConstants.SUPER_APP]: { state: superAppInitialState, handler: superAppHandlers }
};
