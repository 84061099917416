export const LOGIN_PAGE = {
    "buy-used-cars": "homepage",
    "home": "sellHomepage",
    "myBookings": "myBookings",
    "car-details": "carDetailsPage",
    "car-listing": "carListingPage",
    "bank-statement-upload": "deeplink",
    "interest-form": "cfForm",
    "my-wishlist": "wishlist",
    "buy-used-cars-home": "buyUsedCarsHome",
    "used-car-loan": "loanTab"
};
