import React, { useState, useEffect } from "react";
import HelpSupportModal from "../help-support-modal";
import PropTypes from "prop-types";
import RequestCallbackModal from "../request-callback-modal";

const SupportWidget = ({ modalStatus, history, setModalSourceConnect, route }) => {
    const [showCallBackModal, setShowCallBackModal] = useState(false);

    useEffect(() => {
        const {location} = history || {};
        const {pathname} = location || {};
        if (pathname !== "/") {
            setModalSourceConnect(false);
        }
    }, [history, history.location.pathname, setModalSourceConnect]);
    useEffect(() => {
        if (!modalStatus) {
            setShowCallBackModal(false);
        }
    }, [modalStatus]);
    return (
        <React.Fragment>
            <HelpSupportModal route={route}
                setShowCallBackModal={setShowCallBackModal}
            />
            {!!(modalStatus && showCallBackModal) && <RequestCallbackModal
                setShowCallBackModal={setShowCallBackModal}
                showCallBackModal={showCallBackModal}
            />}
        </React.Fragment>
    );
};

SupportWidget.propTypes = {
    modalStatus: PropTypes.bool,
    history: PropTypes.object,
    setModalSourceConnect: PropTypes.func,
    route: PropTypes.object
};

export default SupportWidget;
