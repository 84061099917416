import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RequestCallbackSection from "./component";
import { leadpost } from "./action";
import { withRouter } from "react-router";
import withLoginSignupPopup from "../../shared/with-login-signup";

const mapStateToProps = ({
    user: { mobile, isLoggedIn }

}) => ({
    mobile,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    leadConnect: leadpost
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLoginSignupPopup(RequestCallbackSection)));
