import { appUrl } from "../../constants/url-constants";
import { MODAL_USER_ACTION, appRedirect } from "../../constants/app-constants";
export const getOtherAnchorURL = (route, pathname) => {
    let url;
    if (pathname === MODAL_USER_ACTION.SELL) {
        url = `${appUrl()}${appRedirect.SELLUSEDCAR}-${route}/`;
    } else {
        url = `${appUrl()}/${route}/`;
    }
    return url;
};

export const getAllCity = (pathname) => {
    let url;
    if (pathname === MODAL_USER_ACTION.SELL) {
        url = `${appUrl()}/sell-used-cars/`;
    } else if (pathname === MODAL_USER_ACTION.CF) {
        url = `${appUrl()}/used-car-loan/`;
    } else {
        url = `${appUrl()}/buy-used-cars/`;
    }
    return url;
};
