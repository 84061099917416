import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import whatsApp from "./images/whatsapp.svg";
import Checkbox from "../../shared/checkbox";

const CheckoutLoginWhatsappRevamp = ({
    onWhatsappToggle,
    checked,
    toggleRef
}) => {
    return (
        <div styleName={"styles.wrapper"}>
            <div className={"media"}>
                <img src={whatsApp} alt="whatsapp"/>
                <div className={"media-body"}>
                    <p><strong>Get instant updates</strong> <br/>from CARS24 on your <strong>WhatsApp</strong></p>
                </div>
            </div>
            <div styleName={"styles.checkboxWrap"}>
                <Checkbox
                    inputRef={toggleRef}
                    onClickCallback={onWhatsappToggle}
                    checkedStatus={checked}
                />
            </div>
        </div>
    );
};

CheckoutLoginWhatsappRevamp.propTypes = {
    onWhatsappToggle: PropTypes.func,
    checked: PropTypes.bool,
    toggleRef: PropTypes.object
};

export default CheckoutLoginWhatsappRevamp;
