import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_BUYER_TESTIMONIAL_DATA
        FETCH_BUYER_TESTIMONIAL_DATA_SUCCESS
        FETCH_BUYER_TESTIMONIAL_DATA_FAILURE
        UPDATE_TESTIMONIAL_SSR
    `,
    {
        prefix: "buyer-testimonials/"
    }
);
