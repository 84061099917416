import React from "react";
import loadable from "@loadable/component";
const ReactLazyLoad = loadable.lib(() => import("react-lazy-load-image-component"));

const LazyImage = (props) => (
    <ReactLazyLoad fallback={<img {...props} />}>
        {({ LazyLoadImage }) => <LazyLoadImage {...props} effect="blur" />}
    </ReactLazyLoad>
);

export default LazyImage;

export const LazyComponent = (props) => (
    <ReactLazyLoad fallback={<React.Fragment />}>
        {({ LazyLoadComponent }) => <LazyLoadComponent {...props} />}
    </ReactLazyLoad>
);
