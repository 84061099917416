import React, { useMemo } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { SwiperSlider } from "../../shared/swiper";
import { NewsCard } from "@cars24/turbo-web/lib/news-card";
import parseNewsList from "../../../utils/helpers/parse-news-list";
import { LazyComponent } from "../../shared/lazy-image";
import { InView } from "react-intersection-observer";
import { newsClicked, newsViewed } from "../../../constants/tracking-constants/super-home-events";
import { sendCustomGaEvents } from "../../../tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { stripAndSliceHtml } from "../../../utils/helpers/strip-and-slice-html";
const SuperWebNewsSection = loadable(() => import("../super-web-news-section"));

const SuperWebNewsWrapper = ({
    superApp,
    sectionKey,
    platformLabel
}) => {

    const { parsedData } = superApp || {};
    const { title, data: sectionData } = parsedData[sectionKey] || {};

    const onChangeViewHandler = async (inView, label) => {
        if (inView) {
            await yieldToMain();
            sendCustomGaEvents({
                ...newsViewed,
                eventLabel: `${label} | ${platformLabel}`
            });
        }
    };

    const onClickHandler = async (label) => {
        await yieldToMain();
        sendCustomGaEvents({
            ...newsClicked,
            eventLabel: `${label} | ${platformLabel}`
        });
    };

    const newsCardData = useMemo(() => {
        const { content } = sectionData || {};
        const { data: newsDataList = []} = content || {};
        const newsList = parseNewsList(newsDataList);
        const newsCardList = newsList.map((newsItem, idx) => {
            const {
                imageUrl,
                tag,
                title: newsTitle,
                summary,
                redirectionUrl,
                readTime,
                authorName,
                publishedAt,
                authorProfileUrl
            } = newsItem || {};

            const newsLabel = `${tag} | ${newsTitle}`;

            return (
                <SwiperSlider key={idx}>
                    <InView
                        threshold={1}
                        onChange={(inView) => onChangeViewHandler(inView, newsLabel)}
                        onClick={() => onClickHandler(newsLabel)}
                    >
                        <NewsCard
                            imageUrl={imageUrl}
                            tag={tag}
                            title={newsTitle}
                            summary={stripAndSliceHtml(summary)}
                            authorName={authorName}
                            authorProfileUrl={authorProfileUrl}
                            redirectionUrl={redirectionUrl}
                            readTime={readTime}
                            publishedAt={publishedAt}
                        />
                    </InView>
                </SwiperSlider>
            );

        });

        return newsCardList || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionData]);

    return newsCardData && newsCardData.length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <SuperWebNewsSection
                    newsCardData={newsCardData}
                    title={title}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment/>);

};

SuperWebNewsWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    platformLabel: PropTypes.string
};

export default SuperWebNewsWrapper;
