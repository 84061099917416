export const resetReducerKeys = {
    RESET_CAR_FINDER: "RESET_CAR_FINDER",
    RESET_BRANDS: "RESET_BRANDS",
    RESET_MODELS: "RESET_MODELS",
    RESET_YEARS: "RESET_YEARS",
    RESET_VARIANTS: "RESET_VARIANTS",
    RESET_REGISTRATION_STATES: "RESET_REGISTRATION_STATES",
    RESET_KILOMETERS_DRIVEN: "RESET_KILOMETERS_DRIVEN",
    SEO_CAR_OVERVIEW: "SEO_CAR_OVERVIEW",
    RESET_TESTIMONIALS: "RESET_TESTIMONIALS",
    RESET_EXPERTS: "RESET_EXPERTS",
    RESET_FAQS: "RESET_FAQS",
    RESET_RECENTLY_INSPECTED: "RESET_RECENTLY_INSPECTED",
    RESET_BUY_USED_CAR_HOME: "RESET_BUY_USED_CAR_HOME",
    RESET_HEADER: "RESET_HEADER",
    RESET_FOOTER: "RESET_FOOTER",
    META_TAGS: "RESET_META_TAGS",
    RESET_LEAD_DATA: "RESET_LEAD_DATA",
    RESET_SEO_MODELS: "RESET_SEO_MODELS",
    RESET_BRANCH_LOCATION: "RESET_BRANCH_LOCATION",
    RESET_CITIES: "RESET_CITIES",
    RESET_APPOINTMENT_LOCATION: "RESET_APPOINTMENT_LOCATION",
    RESET_APPOINTMENT_DETAILS: "RESET_APPOINTMENT_DETAILS",
    RESET_USER: "RESET_USER",
    RESET_LOCATION: "RESET_LOCATION",
    RESET_RESCHEDULE: "RESET_RESCHEDULE",
    RESET_CONFIRMATION_DETAILS: "RESET_CONFIRMATION_DETAILS",
    RESET_SETTINGS: "RESET_SETTINGS",
    RESET_WIDGET: "RESET_WIDGET",
    RESET_REVIEW: "RESET_REVIEW",
    RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",
    RESET_REVIEW_SUBMISSION: "RESET_REVIEW_SUBMISSION",
    RESET_RC_TRANSFER: "RESET_RC_TRANSFER",
    RESET_TOP_SELLING_USED_CARS: "RESET_TOP_SELLING_USED_CARS",
    RESET_NEED_HELP: "RESET_NEED_HELP",
    RESET_SEO_TESTIMONIALS: "RESET_SEO_TESTIMONIALS",
    RESET_RATED_TESTIMONIALS: "RESET_RATED_TESTIMONIALS",
    RESET_BRANCH_FILTER: "RESET_BRANCH_FILTER",
    RESET_TRUECALLER: "RESET_TRUECALLER",
    RESET_RTO_INFO: "RESET_RTO_INFO",
    RESET_LEAD_BRANCHES: "RESET_LEAD_BRANCHES",
    RESET_LEAD_BRANCH_DATE: "RESET_LEAD_BRANCH_DATE",
    RESET_LEAD_PROGRESS_BAR: "RESET_LEAD_PROGRESS_BAR",
    RESET_AB_EXPERIMENTS: "RESET_AB_EXPERIMENTS",
    RESET_CAR_LIST: "RESET_CAR_LIST",
    RESET_FILTERS: "RESET_FILTERS",
    RESET_CONFIG: "RESET_CONFIG",
    RESET_LEAD_RTO: "RESET_LEAD_RTO",
    RESET_CAR_DETAILS: "RESET_CAR_DETAILS",
    RESET_EXPLORE_USED_CARS: "RESET_EXPLORE_USED_CARS",
    RESET_SIMILAR_CARS: "RESET_SIMILAR_CARS",
    RESET_RECENTLY_VIEWED_CARS: "RESET_RECENTLY_VIEWED_CARS",
    RESET_SELECT_OFFER_PRICE: "RESET_SELECT_OFFER_PRICE",
    RESET_PARKING_YARD: "RESET_PARKING_YARD",
    FEATURED_CARS: "FEATURED_CARS",
    RESET_CONTENT_HOOK: "RESET_CONTENT_HOOK",
    RESET_LEGACY_LEAD_FORM: "RESET_LEGACY_LEAD_FORM",
    RESET_SELL_BIKE: "RESET_SELL_BIKE",
    RESET_SCRIPT_LOADER: "RESET_SCRIPT_LOADER",
    RESET_REFER: "RESET_REFER",
    RESET_FRANCHISE_FORM: "RESET_FRANCHISE_FORM",
    RESET_PARTNER_FORM: "RESET_PARTNER_FORM",
    RESET_LOAN_FORM: "RESET_LOAN_FORM",
    RESET_BIKE_FORM: "RESET_BIKE_FORM",
    RESET_BIKE_BRANDS: "RESET_BIKE_BRANDS",
    RESET_BIKE_BRANCHES: "RESET_BIKE_BRANCHES",
    RESET_BIKE_BRANCH_DATE: "RESET_BIKE_BRANCH_DATE",
    RESET_CLASSIFIED: "RESET_CLASSIFIED",
    MAKE_AN_OFFER: "MAKE_AN_OFFER",
    USED_CAR_LEAD_INFO: "USED_CAR_LEAD_INFO",
    RESET_TOP_SELLING_CARS: "RESET_TOP_SELLING_CARS",
    RESET_FEATURED_CARS: "RESET_FEATURED_CARS",
    RESET_LISTING_INFO: "RESET_LISTING_INFO",
    RESET_LOAN_CALCULATION_DATA: "RESET_LOAN_CALCULATION_DATA",
    RESET_BUYER_SELLER_DETAIL: "RESET_BUYER_SELLER_DETAIL",
    RESET_BIKE_RESCHEDULE: "RESET_BIKE_RESCHEDULE",
    RESET_BIKE_CONFIRMATION_DETAILS: "RESET_BIKE_CONFIRMATION_DETAILS",
    RESET_WISHLISTED_CARS: "RESET_WISHLISTED_CARS",
    RESET_WISHLISTED_LOGIN: "RESET_WISHLISTED_LOGIN",
    RESET_TOAST_MESSAGE: "RESET_TOAST_MESSAGE",
    RESET_CAR_FINDER_LISTING: "RESET_CAR_FINDER_LISTING",
    RESET_CAR_FINDER_CAR_LIST: "RESET_CAR_FINDER_CAR_LIST",
    RESET_DEAL_PAYMENT_INFO: "RESET_DEAL_PAYMENT_INFO",
    RESET_GS_BUY_CAR_DETAILS: "RESET_GS_BUY_CAR_DETAILS",
    RESET_CONSUMER_FINANCE: "RESET_CONSUMER_FINANCE",
    RESET_MY_BOOKINGS: "RESET_MY_BOOKINGS",
    RESET_MY_BOOKING_SUMMARY: "RESET_MY_BOOKING_SUMMARY",
    RESET_MY_BOOKING_SUMMARY_OLD: "RESET_MY_BOOKING_SUMMARY_OLD",
    RESET_LOCATION_PICKER_MODAL: "RESET_LOCATION_PICKER_MODAL",
    RESET_CHECKOUT: "RESET_CHECKOUT",
    RESET_CHAT_ICON: "RESET_CHAT_ICON",
    RESET_CALLBACK_DETAILS: "RESET_CALLBACK_DETAILS",
    RESET_RECOMMENDED_CARS: "RESET_RECOMMENDED_CARS",
    RESET_ALL_TICKETS: "RESET_ALL_TICKETS",
    RESET_SELL_ONLINE_OFFER_PRICE: "RESET_SELL_ONLINE_OFFER_PRICE",
    RESET_HELP_AND_SUPPORT: "RESET_HELP_AND_SUPPORT",
    RESET_INVESTORS: "RESET_INVESTORS",
    RESET_FASTER_CHECKOUT: "RESET_FASTER_CHECKOUT",
    RESET_RETURN_FLOW: "RESET_RETURN_FLOW",
    RESET_APPOINTMENT_REVAMP_DETAILS: "RESET_APPOINTMENT_REVAMP_DETAILS",
    RESET_APPOINTMENT_LOCATION_PICKER: "RESET_APPOINTMENT_LOCATION_PICKER",
    RESET_APPOINTMENT_SELL_ONLINE_LEAD_FORM: "RESET_APPOINTMENT_SELL_ONLINE_LEAD_FORM",
    RESET_DSA: "RESET_DSA",
    RESERVATION_CONFIRMED: "RESERVATION_CONFIRMED",
    RESET_POST_INSPECTION: "RESET_POST_INSPECTION",
    RESET_BANK_OFFER_COUPONS: "RESET_BANK_OFFER_COUPONS",
    REGISTRATION_NO: "REGISTRATION_NO",
    RESET_CF_POPUP: "RESET_CF_POPUP",
    RESET_REGISTRATION_NO: "RESET_REGISTRATION_NO",
    FILTER_V2: "FILTER_V2",
    RESET_NEED_HELP_POPUP: "RESET_NEED_HELP_POPUP",
    RESET_POST_INSPECTION_DIY: "RESET_POST_INSPECTION_DIY",
    RESET_USER_WISHLIST: "RESET_USER_WISHLIST",
    RESET_NEARBY_CITIES: "RESET_NEARBY_CITIES",
    RESET_RECOMMENDED_CARS_FOR_YOU: "RESET_RECOMMENDED_CARS_FOR_YOU"
};

