import React, { useState, useMemo } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import parseVideoList from "../../../utils/helpers/parse-video-list";
import { preventPropagation } from "../../../utils/helpers/prevent-propagation";
import { SwiperSlider } from "../../shared/swiper";
import { VideoTestimonialCard } from "@cars24/turbo-web/lib/video-testimonial-card";
import { LazyComponent } from "../../shared/lazy-image";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { sendCustomGaEvents } from "../../../tracking";
import { videoClick } from "../../../constants/tracking-constants/super-home-events";
import { NUMBER, WHITE_LOGO_URL } from "../../../constants/app-constants";
const SuperWebVideoTestimonialSection = loadable(() => import("../super-web-video-testimonial-section"));

const SuperWebVideoTestimonialWrapper = ({
    superApp,
    sectionKey,
    platformLabel
}) => {
    const { parsedData } = superApp || {};
    const { data: sectionData } = parsedData[sectionKey] || {};
    const { content: videoSectionList } = sectionData || {};
    const videoGallery = useMemo(() => {
        return parseVideoList(videoSectionList, MEDIA_DEVICE_TYPES.DESKTOP);
    }, [videoSectionList]);

    const [showVideoPlayer, setShowVideoPlayer] = useState(false);
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);

    const onClickVideoImageHandler = async (index, videoLabel) => {
        setShowVideoPlayer(true);
        setActiveVideoIndex(index);

        await yieldToMain();
        sendCustomGaEvents({
            ...videoClick,
            eventLabel: `${videoLabel} | ${index + NUMBER.ONE} | ${platformLabel}`

        });
    };

    const logoClickHandler = (e) => {
        preventPropagation(e);
    };

    const bannerData = useMemo(() => {
        const videoThumbailList = videoGallery.map((videoItem, idx) => {
            const {
                thumbnailImage,
                name,
                description,
                location
            } = videoItem || {};

            const videoLabel = `${name} ${location}`;

            return (
                <SwiperSlider key={idx}>
                    <VideoTestimonialCard
                        imageUrl={thumbnailImage}
                        profilename={name}
                        location={location}
                        description={description}
                        clickHandler={onClickVideoImageHandler.bind(null, idx, videoLabel)}
                        rightIconClickHandler={logoClickHandler}
                        iconRightUrl={WHITE_LOGO_URL}
                    />

                </SwiperSlider>
            );

        });

        return videoThumbailList || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoGallery]);

    return videoGallery && videoGallery.length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <SuperWebVideoTestimonialSection
                    bannerData={bannerData}
                    videoGallery={videoGallery}
                    showVideoPlayer={showVideoPlayer}
                    setShowVideoPlayer={setShowVideoPlayer}
                    activeVideoIndex={activeVideoIndex}
                    setActiveVideoIndex={setActiveVideoIndex}
                    platformLabel={platformLabel}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebVideoTestimonialWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    platformLabel: PropTypes.string
};

export default SuperWebVideoTestimonialWrapper;
