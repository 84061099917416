import React from "react";
import PropTypes from "prop-types";
import { appUrl } from "../../../constants/url-constants";

const LiLink = ({
    title,
    url,
    value,
    target = "",
    onClickCallback = () => { }
    // isUsedCarRoute
}) => {

    const getLinkUrl = () => {
        // if (isUsedCarRoute) {
        //     if (title === "Privacy Policy") {
        //         return `${appUrl()}/privacy-policy-buy-cars/`;
        //     }
        //     if (title === "Terms and Conditions") {
        //         return `${appUrl()}/terms-and-conditions-buy-cars/`;
        //     }
        // }
        if (title === "Need a Loan") {
            return `${appUrl()}/used-car-loan/`;
        }
        return url;
    };

    return (
        <li>
            <a
                onClick={(e) => onClickCallback({ e, title, url, value })}
                href={getLinkUrl()}
                target={target}
            >
                {title || value}
            </a>
        </li>
    );
};

LiLink.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    url: PropTypes.string.isRequired,
    onClickCallback: PropTypes.func,
    target: PropTypes.string
    // isUsedCarRoute: PropTypes.bool
};

export default LiLink;
