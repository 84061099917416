export default (videoDataList = [], deviceType) => {
    return (videoDataList || []).map((videoItem) => {
        const {
            title: name,
            location,
            thumbnailImage,
            redirectionUrl: description,
            video
        } = videoItem || {};
        const {
            url: videoUrl
        } = (video || {})[deviceType] || {};

        return {
            videoUrl,
            thumbnailImage,
            name,
            description,
            location
        };
    });

};
