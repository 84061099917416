import React, { useEffect } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";
import closeButton from "./images/close-icon.svg";
import InputMobile from "../../shared/input-mobile";
import Button from "../../shared/button";
import CheckoutLoginWhatsappRevamp from "../checkout-login-whatsapp-revamp";
import CountdownTimer from "../../shared/countdown-timer";
import { Link } from "react-router-dom";
import { NUMBER } from "../../../constants/app-constants";
import LazyImage from "../../shared/lazy-image";
import Logo from "./images/logo.svg";
import { sendCustomGaEvents } from "../../../tracking";
import { loginScreenClosed, loginScreenOpened, loginOtpRequested, resendOtpClicked, phoneNumberEdited } from "./tracking";
import InputOTP from "../../shared/input-otp";
import { LOGIN_POPUP_HEADING } from "./constants";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import useToggle from "../../../hooks/use-toggle";
import { TU_CIBIL_PDF } from "../../../constants/cf-constants";
const TermsOfUsePopup = loadable(() => import("../terms-of-use-popup"));

const LoginControl = ({
    heading = "Please Login to Proceed",
    onClose,
    showOtpField,
    custPhone,
    otp,
    otpRef,
    phoneRef,
    toggleRef,
    showLoader,
    isNextDisabled,
    onPhoneChange,
    onOtpChange,
    onGetOTP,
    allowWhatsapp,
    errorMessage,
    onWhatsappChange,
    onOtpVerify,
    showResendOtp,
    onCompleteTimer,
    setShowOtpField,
    getEventCategory,
    isOTPInvalid,
    setErrorMessage,
    carAppId,
    cityInfo,
    isEditable,
    overlayClass = "loginOverlay"
}) => {

    const { city_name: cityName, city_id: cityId } = cityInfo || {};

    const [showCibilConsent, setShowCibilConsent] = useToggle(false);

    const getHeading = (head) => {
        if (head === LOGIN_POPUP_HEADING.LOGIN_TO_VIEW_YOUR_SELECTIONS) {
            return (
                <h2><span>Log in</span> to <br /> view your selections</h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.LOGIN_TO_BOOK_TEST_DRIVE) {
            return (
                <h2><span>Log in</span> to <br /> conveniently book a test drive</h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.LOGIN_TO_CHECK_ELIGIGBILITY) {
            return (
                <h2><span>Log in</span> to <br /> check your loan eligibility</h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.LOGIN_TO_GET_SERVICE_HISTORY_REPORT) {
            return (
                <h2><span>Log in</span> to <br /> get service history report</h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.LOGIN_TO_VIEW_BOOKINGS) {
            return (
                <h2><span>Log in</span> to <br /> view your bookings</h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.SIGNIN_TO_SEE_WISHLIST) {
            return (
                <h2><span>Sign in</span> to <br /> see your <span>Wishlist</span></h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.SIGNIN_TO_WISHLIST) {
            return (
                <h2><span>Sign in</span> to <br /> <span>Wishlist</span></h2>
            );
        } else if (head === LOGIN_POPUP_HEADING.LOGIN_TO_CHECK_SERVICE_HISTORY_REPORT) {
            return (
                <h2><span>Log in</span> to <br /> check service history report</h2>
            );
        } if (head === LOGIN_POPUP_HEADING.LOGIN_TO_SET_NOTIFICATION_ALERT) {
            return (
                <h2><span>Log in</span> to <br /> set Notification Alert</h2>
            );
        } else {
            return (
                <h2><span>Log in</span> to <br /> continue</h2>
            );
        }
    };

    useEffect(() => {
        sendCustomGaEvents({
            ...loginScreenOpened,
            eventCategory: getEventCategory(),
            ...(carAppId && { eventLabel: carAppId }),
            ...getGaEventDimensionObject({
                cityName,
                cityId
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        onClose();
        setErrorMessage(null);
        sendCustomGaEvents({
            ...loginScreenClosed,
            eventCategory: getEventCategory()
        });
    };

    const onRequestOTP = () => {
        onGetOTP();
        sendCustomGaEvents({
            ...loginOtpRequested,
            eventCategory: getEventCategory()
        });
    };

    const handleResendOTP = () => {
        onGetOTP();
        sendCustomGaEvents({
            ...resendOtpClicked,
            eventCategory: getEventCategory()
        });
    };

    const handleEdit = () => {
        setShowOtpField(false);
        sendCustomGaEvents({
            ...phoneNumberEdited,
            eventCategory: getEventCategory()
        });
    };

    const onClickTermsOfUse = () => {
        sendCustomGaEvents({
            event: "NBFC_terms_of_use_cta_click",
            eventAction: "NBFC_terms_of_use_cta_click"
        });
        setShowCibilConsent();
    };

    const onClickTuCibil = () => {
        sendCustomGaEvents({
            event: "NBFC_tu_cibil_terms_of_use_cta",
            eventAction: "NBFC_tu_cibil_terms_of_use_cta"
        });
    };

    return showCibilConsent ? <TermsOfUsePopup onClick={setShowCibilConsent} /> : (
        <Modal isOpen={true} overlayClass={overlayClass}>
            <div styleName={"styles.modalWrap"}>
                <span styleName={"styles.close"}>
                    <img src={closeButton} alt="close" onClick={handleClose} />
                </span>
                <div styleName={"styles.leftPanel"}>
                    <LazyImage src={Logo} alt="CARS24" />
                    <h4>A whole new <br /> world of Cars</h4>
                </div>
                <div styleName={"styles.rightPanel"}>
                    {getHeading(heading)}

                    {
                        !showOtpField ? (
                            <React.Fragment>
                                <div styleName={"styles.formWrap"}>
                                    <h4 styleName={"styles.heading"}>Mobile number</h4>
                                    <div styleName={"styles.formGroup"}>
                                        <InputMobile
                                            isPrefixAsFixed={true}
                                            isLabelShift={false}
                                            placeholder="999 999 9999"
                                            inputRef={phoneRef}
                                            value={custPhone}
                                            onChange={onPhoneChange}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div styleName={"styles.whatsappWrap"}>
                                        <CheckoutLoginWhatsappRevamp
                                            toggleRef={toggleRef}
                                            onWhatsappToggle={onWhatsappChange}
                                            checked={allowWhatsapp} />
                                    </div>

                                    <Button disabled={isNextDisabled} onClick={onRequestOTP} text="GET OTP" isLoading={showLoader} />
                                </div>
                                <div styleName={`styles.messageWrap styles.messageText`}>
                                    <React.Fragment>
                                        <p>By logging in, you agree to CARS24's</p>
                                        <ul>
                                            <li>
                                                CARS24’s Privacy <Link to={"/privacy-policy-buy-cars"}>Privacy Policy</Link> and <Link to={"/terms-and-conditions"}>Terms &amp; Conditions</Link>
                                            </li>
                                            <li>
                                                CARS24 NBFC’s <span onClick={onClickTermsOfUse}>Terms of Use</span> and <a href={TU_CIBIL_PDF} target="_blank" onClick={onClickTuCibil}>TU CIBIL Terms of Use</a>
                                            </li>
                                        </ul>
                                    </React.Fragment>

                                </div>

                            </React.Fragment>
                        ) : (

                            <div styleName={"styles.formWrap"}>
                                <h4 styleName={"styles.heading"}>Mobile number</h4>
                                <div styleName={"styles.formGroup"}>
                                    <InputMobile
                                        isPrefixAsFixed={true}
                                        isLabelShift={false}
                                        placeholder="999 999 9999"
                                        inputRef={phoneRef}
                                        value={custPhone}
                                        onChange={onPhoneChange}
                                        disabled={!isEditable}
                                    />
                                    { isEditable && <span styleName={"styles.edit"} onClick={handleEdit}>EDIT</span> }
                                </div>
                                {/* Enter OTP */}
                                <h4 styleName={"styles.heading"}>OTP</h4>
                                <div styleName={"styles.formGroup"}>
                                    <InputOTP
                                        inputRef={otpRef}
                                        value={otp}
                                        onChange={onOtpChange}
                                        isLabelShift={false}
                                        placeholder=""
                                    />
                                    {isOTPInvalid && <p styleName={"styles.textError"}>{errorMessage || "Incorrect OTP, please try again."}</p>}
                                </div>
                                <p styleName={"styles.otpSentText"}>
                                    {
                                        !showResendOtp ?
                                            <React.Fragment>
                                                Resend OTP in
                                                <span className="mr-1 ml-1">
                                                    <CountdownTimer
                                                        className="mr-1 ml-1"
                                                        onlyText={true}
                                                        interval={30}
                                                        onComplete={onCompleteTimer}
                                                    />
                                                </span>
                                                seconds
                                            </React.Fragment> :
                                            <span styleName={"styles.resendOtp"} role="button" onClick={handleResendOTP} >Resend OTP</span>
                                    }
                                </p>
                                <Button text="VERIFY" onClick={onOtpVerify} disabled={otp.length < NUMBER.FOUR} isLoading={showLoader} />
                            </div>
                        )}

                </div>
            </div>
        </Modal>
    );
};

LoginControl.propTypes = {
    heading: PropTypes.string,
    onClose: PropTypes.func,
    showOtpField: PropTypes.bool,
    custPhone: PropTypes.string,
    otp: PropTypes.string,
    otpRef: PropTypes.object,
    phoneRef: PropTypes.object,
    toggleRef: PropTypes.object,
    showLoader: PropTypes.string,
    isNextDisabled: PropTypes.bool,
    onPhoneChange: PropTypes.func,
    onOtpChange: PropTypes.func,
    onGetOTP: PropTypes.func,
    allowWhatsapp: PropTypes.bool,
    errorMessage: PropTypes.string,
    onWhatsappChange: PropTypes.func,
    onOtpVerify: PropTypes.func,
    showResendOtp: PropTypes.func,
    onCompleteTimer: PropTypes.string,
    setShowOtpField: PropTypes.func,
    getEventCategory: PropTypes.func,
    isOTPInvalid: PropTypes.bool,
    setErrorMessage: PropTypes.func,
    carAppId: PropTypes.string,
    cityInfo: PropTypes.object,
    isEditable: PropTypes.bool,
    overlayClass: PropTypes.string
};

export default LoginControl;
