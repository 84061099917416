export const clearEcommerce = {
    ecommerce: null
};
export const ga4SelectItem = {
    event: "select_content"
};
export const ga4ViewItem = {
    event: "view_item"
};
export const ga4BeginCheckout = {
    event: "begin_checkout"
};
export const ga4AddtoCart = {
    event: "add_to_cart"
};
export const ga4Purchase = {
    event: "purchase"
};
export const ga4Signin = {
    event: "signin"
};
export const ga4Signout = {
    event: "signout"
};
export const ga4CarSearchEvent = {
    event: "search"
};
export const carCardDetails = {
    event: "cardetail"
};
