/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendOTP, verifyOtp, login } from "./action";
import { getProfileDetails } from "../../configuration/actions";
import isMobileNumberValid from "../../../utils/helpers/is-mobile-number-valid";
import { NUMBER } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import { loginSuccess } from "./tracking";

const OTP_LENGTH_LIMIT = 4;

export default function withLoginSignupPopup(Component) {
    const LoginSignupPopupComponent = (props) => {
        const {
            sendOTPConnect,
            verifyOtpConnect,
            loginConnect,
            getProfileDetailsConnect,
            isLoggedIn,
            gaId,
            userPincode
        } = props;
        const otpRef = useRef(null);
        const [isNumberVerified, setIsNumberVerified] = useState(false);
        const [isOtpVerified, setIsOtpVerified] = useState(false);
        const [isOtpVisible, setIsOtpVisible] = useState(false);
        const [showTimer, setShowTimer] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [showLoader, setShowLoader] = useState(false);
        const [modalStatusVal, setModalstatusVal] = useState(false);

        // For Initial and reset
        useEffect(() => {
            if (isLoggedIn) {
                setIsNumberVerified(true);
                setIsOtpVerified(true);
            }
            return () => {
                setIsNumberVerified(false);
                setIsOtpVerified(false);
                setIsOtpVisible(false);
                setShowTimer(false);
                setErrorMessage("");
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [modalStatusVal]);

        const focusOtp = (isFocus = true) => {
            if (otpRef && otpRef.current) {
                window.setTimeout(() => {
                    if (isFocus) {
                        otpRef.current.focus();
                    } else {
                        otpRef.current.blur();
                    }
                }, NUMBER.TWO_HUNDRED);
            }
        };

        const onMobileNoChange = (mobileNo) => {
            if (isMobileNumberValid(mobileNo)) {
                sendOTPConnect(mobileNo).then(() => {
                    setIsNumberVerified(true);
                    setIsOtpVisible(true);
                    setShowTimer(true);
                    focusOtp();
                }).catch(() => {
                    setIsNumberVerified(false);
                    setIsOtpVisible(false);
                });
            } else {
                setIsNumberVerified(false);
                setIsOtpVisible(false);
            }
        };

        const onCompleteTimer = () => {
            setShowTimer(false);
        };

        const resetErrorMessage = () => {
            setErrorMessage("");
        };

        const onResendClick = (mobileNo) => {
            setShowTimer(true);
            resetErrorMessage();
            sendOTPConnect(mobileNo);
        };

        const validateOtp = (number) => {
            return number.length === OTP_LENGTH_LIMIT;
        };

        const sendLoginEvent = (data) => {
            const userPayload = {
                uuid: data.user_id,
                phonenumber: `${data.phone_number}`,
                email: data.user_email,
                pincode: userPincode,
                loggedin: true,
                gaId
            };
            sendCustomGaEvents({
                ...loginSuccess,
                ...userPayload
            });
        };

        const handleUserLogin = (inputOtp, mobileNo) => {
            resetErrorMessage();
            setShowLoader(true);
            const params = {
                phone_number: mobileNo,
                otp: inputOtp
            };
            return new Promise((resolve, reject) => {
                verifyOtpConnect(params).then(response => {
                    if (response.error) {
                        const { error: { error_message: errormessage } } = response;
                        setErrorMessage(errormessage);
                        return;
                    }
                    // setIsOtpVisible(false);
                    setShowTimer(false);
                    setIsOtpVerified(true);
                    focusOtp(false);
                    const responseData = response.data || {};
                    const loginUrl = ((response.data || {}).login_url || "");
                    const accessToken = loginUrl.split("access-token=")[1] || responseData["access-token"];
                    const payload = {
                        "access-token": accessToken,
                        redirect_url: encodeURIComponent(window.location.href)
                    };

                    loginConnect(payload).then(() => {
                        getProfileDetailsConnect().then((profileResponse) => {
                            const { data: { data: { user } } } = profileResponse;
                            sendLoginEvent(user);
                            setShowLoader(false);
                            resolve(user);
                        });
                    }).catch(() => {
                        getProfileDetailsConnect().then((profileResponse) => {
                            const { data: { data: { user } } } = profileResponse;
                            sendLoginEvent(user);
                            setShowLoader(false);
                            resolve(user);
                        });
                    });

                }).catch(error => {
                    const errorCode = error &&
                        error.errors &&
                        error.errors[0] &&
                        error.errors[0].error || "";
                    setErrorMessage(errorCode);
                    reject(error);
                    setShowLoader(false);
                });
            });
        };

        const onSubmitUserDetails = (inputOtp, mobileNo) => {
            handleUserLogin(inputOtp, mobileNo);
        };

        const onOtpChange = (otp, mobileNo) => {
            if (validateOtp(otp)) {
                onSubmitUserDetails(otp, mobileNo);
            }
        };

        const modalStatus = (status) => {
            setModalstatusVal(status);
        };

        const otpVisibility = (action) => {
            setIsOtpVisible(action);
        };

        return (
            <Component
                {...props}
                onMobileNoChange={onMobileNoChange}
                onOtpChange={onOtpChange}
                isNumberVerified={isNumberVerified}
                isOtpVerified={isOtpVerified}
                isOtpVisible={isOtpVisible}
                showTimer={showTimer}
                onCompleteTimer={onCompleteTimer}
                onResendClick={onResendClick}
                errorMessage={errorMessage}
                otpRef={otpRef}
                isLoading={showLoader}
                modalStatus={modalStatus}
                otpVisibility={otpVisibility}
                isLoggedIn = {isLoggedIn}
            />
        );

    };

    LoginSignupPopupComponent.propTypes = {
        verifyOtpConnect: PropTypes.func,
        sendOTPConnect: PropTypes.func,
        loginConnect: PropTypes.func,
        leadConnect: PropTypes.func,
        getProfileDetailsConnect: PropTypes.func,
        isLoggedIn: PropTypes.bool,
        mobile: PropTypes.number,
        showCallBackPopup: PropTypes.bool,
        gaId: PropTypes.string,
        userPincode: PropTypes.number
    };
    const mapStateToProps = (
        {
            user: { isLoggedIn, gaId },
            location: {
                pincode: userPincode
            }
        },
    ) => ({
        isLoggedIn,
        gaId,
        userPincode
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        verifyOtpConnect: verifyOtp,
        sendOTPConnect: sendOTP,
        loginConnect: login,
        getProfileDetailsConnect: getProfileDetails
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(LoginSignupPopupComponent);
}
