import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import SuperWebBuyCarWrapper from "./component";

const mapStateToProps = ({
    superApp
}) => ({
    superApp

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperWebBuyCarWrapper));
