import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import withLoginSignupPopup from "../../shared/with-login-signup";
import { setModalSource } from "../help-support-modal/action";
import { leadpost } from "./action";
import RequestCallbackModal from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    leadConnect: leadpost,
    setModalSourceConnect: setModalSource
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLoginSignupPopup(RequestCallbackModal)));
