import { carConsumerService } from "../../service/car-consumer-gateway-api";
import Types from "./types";

const setPosition = ({ lat, lng }) => ({
    type: Types.SET_POSITION,
    lat,
    lng
});

const fetchGeoDetailsInit = () => (
    {type: Types.FETCH_GEO_DETAILS_INIT}
);

const fetchGeoDetailsSuccess = (data, sourceType) => (
    {
        type: Types.FETCH_GEO_DETAILS_SUCCESS,
        data,
        sourceType
    }
);

const fetchGeoDetailsFailure = (error) => (
    {type: Types.FETCH_GEO_DETAILS_FAILURE, error}
);

const fetchGeoDetails = (params, shouldDetailsPersist = true) => (dispatch) => {
    dispatch(fetchGeoDetailsInit());
    return new Promise((resolve, reject) => {
        carConsumerService.fetchGeoDetails(params)
            .then((response) => {
                if (shouldDetailsPersist) {
                    const sourceType = params.pincode ? "pincode" : "location";
                    dispatch(fetchGeoDetailsSuccess(response.data.detail, sourceType));
                }
                resolve(response.data.detail);
            })
            .catch((error) => {
                dispatch(fetchGeoDetailsFailure(error));
                reject(error);
            });
    });
};

const resetError = () => (
    {type: Types.RESET_ERROR}
);

const resetLocationData = (data) => (
    {type: Types.RESET_LOCATION_DATA, data}
);

const updateCityLocationDetails = (data) => (
    {type: Types.UPDATE_CITY_LOCATION_DETAILS, data}
);

const clearLocationCoordinates = () => (
    {type: Types.CLEAR_LAT_LONG}
);

export {
    fetchGeoDetailsSuccess,
    setPosition,
    fetchGeoDetails,
    resetError,
    fetchGeoDetailsFailure,
    resetLocationData,
    updateCityLocationDetails,
    clearLocationCoordinates
};
