import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { SUPER_WEB_BANNER_TYPE, SUPER_APP_SECTION_TYPES } from "../../../constants/super-app-constants";
import getSuperAppBannerMap from "../../../utils/helpers/get-super-app-banner-map";

export const INITIAL_STATE = {
    isLoading: true,
    dataList: null,
    parsedData: null,
    error: null,
    isSSR: false,
    bannerContent: {
        isOpen: false,
        bannerData: "",
        pageType: ""
    },
    isQrVisible: null
};

export const setIsDataLoading = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isLoading: data
    };
};

export const setIsSSR = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isSSR: data
    };
};

export const setIsQrVisible = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isQrVisible: data
    };
};

export const setSuperAppHomeDataInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const setSuperAppHomeDataFailure = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        error: data
    };
};

export const setSuperAppHomeDataSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        dataList,
        parsedData
    } = data || {};

    const appNudgeData = getSuperAppBannerMap(parsedData, SUPER_APP_SECTION_TYPES.SUPER_WEB_GET_APP_SECTION) || {};
    const getAppInlineBannerData = appNudgeData[SUPER_WEB_BANNER_TYPE.SUPER_WEB_GET_APP_PAGE_BANNER];

    return {
        ...state,
        isLoading: false,
        dataList,
        parsedData,
        getAppInlineBannerData
    };
};

export const setBannerPopupContent = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        bannerContent: {
            ...state.bannerContent,
            ...data
        }
    };
};

export const HANDLERS = {
    [Types.IS_DATA_LOADING]: setIsDataLoading,
    [Types.SET_IS_SSR]: setIsSSR,
    [Types.SET_SUPER_APP_HOME_DATA_INIT]: setSuperAppHomeDataInit,
    [Types.SET_SUPER_APP_HOME_DATA_FAILURE]: setSuperAppHomeDataFailure,
    [Types.SET_SUPER_APP_HOME_DATA_SUCCESS]: setSuperAppHomeDataSuccess,
    [Types.SET_BANNER_POPUP_CONTENT]: setBannerPopupContent,
    [Types.IS_QR_VISIBLE]: setIsQrVisible
};

export default createReducer(INITIAL_STATE, HANDLERS);
