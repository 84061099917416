/* eslint-disable max-statements */
/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import Arrow from "./images/right-arrow.svg";
import GreyArrow from "./images/arrow-grey.svg";
import BlueArrow from "./images/blue-arrow.svg";
import WhiteArrow from "./images/white-arrow.svg";
import BlackArrow from "./images/arrow-black.svg";
import GreenArrow from "./images/green-arrow.svg";
import SilverArrow from "./images/light-grey.svg";
import OrangeArrow from "./images/arrow-orange.svg";
import LightGreyArrow from "./images/grey.svg";
import DarkGreenArrow from "./images/dark-green-arrow.svg";

const ArrowIcon = ({orange, grey, blue, white, black, green, silver, lightGrey, darkGreen, rotateBy = 0, width = "24", height = "24", arrowColor, ...props }) => {
    const transform = typeof rotateBy === "string" ? rotateBy : `rotate(${rotateBy}deg)`;
    const style = {
        width,
        height,
        transform
    };

    let icon = Arrow;
    if (orange) {
        icon = OrangeArrow;
    }
    if (grey) {
        icon = GreyArrow;
    }
    if (blue) {
        icon = BlueArrow;
    }
    if (white) {
        icon = WhiteArrow;
    }
    if (black) {
        icon = BlackArrow;
    }
    if (green) {
        icon = GreenArrow;
    }
    if (silver) {
        icon = SilverArrow;
    }
    if (lightGrey) {
        icon = LightGreyArrow;
    }
    if (darkGreen) {
        icon = DarkGreenArrow;
    }
    // TODO: change arrow color to accept string
    if (arrowColor === "white") {
        icon = WhiteArrow;
    }
    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <img width={width} height={height} onClick={handleClick} src={icon} style={style} alt={"arrow"} {...props} />
    );
};

ArrowIcon.propTypes = {
    rotateBy: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    orange: PropTypes.string,
    grey: PropTypes.string,
    green: PropTypes.string,
    onClick: PropTypes.func,
    blue: PropTypes.string,
    white: PropTypes.string,
    black: PropTypes.string,
    arrowColor: PropTypes.string,
    silver: PropTypes.string,
    lightGrey: PropTypes.string,
    darkGreen: PropTypes.string
};

export default ArrowIcon;
