import { AuthService } from "../../../service/auth-service";

const sendOTP = (mobile, c2bApiKey = false) => () => {
    return new Promise((resolve, reject) => {
        AuthService.sendOTP(mobile, "", c2bApiKey)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const verifyOtp = (params, c2bApiKey = false) => () => {
    return new Promise((resolve, reject) => {
        AuthService.verifyOTP(params, c2bApiKey)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const login = (params, c2bApiKey = false) => () => {
    return new Promise((resolve, reject) => {
        AuthService.login(params, c2bApiKey)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const logout = () => () => {
    return new Promise((resolve, reject) => {
        AuthService.logout().then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

export {
    sendOTP,
    verifyOtp,
    login,
    logout
};
