/* eslint-disable max-params */
import { NUMBER } from "../../constants/app-constants";
import parseCookie from "./parse-cookie";
import saveCookie from "./save-cookie";

export const getListFromCookie = (cookieName) => {
    // Get the existing array from the cookie or create an empty array
    let existingArray = [];
    const existingCookie = parseCookie(cookieName);
    if (existingCookie) {
        existingArray = [...JSON.parse(existingCookie)];
    }

    return existingArray;
};

export const storeListInCookie = (cookieName, newValue, limit = NUMBER.FIVE, expiryDays = NUMBER.THREE_SIXTY_FIVE) => {
    const existingArray = [...getListFromCookie(cookieName)];
    if (existingArray.length) {
        // Check if the new value is already in the array
        const existingIndex = existingArray.indexOf(newValue);
        if (existingIndex !== -1) {
            // If the new value is already in the array, remove it from its position
            existingArray.splice(existingIndex, 1);
        }
        // Push the new value to the front of the array
        existingArray.unshift(newValue);

        // If the array length exceeds the limit, remove the last item
        if (existingArray.length > limit) {
            existingArray.pop();
        }

    } else  {
        existingArray.push(newValue);
    }
    // Update the cookie with the updated array
    saveCookie(cookieName, JSON.stringify(existingArray), expiryDays);
};
