/* eslint-disable no-undef */

const parse = JSON.parse;
const stringify = JSON.stringify;

export const getItem = (key) => {
    return parse(localStorage.getItem(key)) || null;
};

export const setItem = (value, key) => {
    if (!value) {
        return null;
    }

    return localStorage.setItem(key, stringify(value));
};

export const clearItem = (key) => {
    return localStorage.removeItem(key);
};

export const getStringItem = (key) => {
    return localStorage.getItem(key) || null;
};
export const setStorage = (value, key) => {
    if (!value) {
        return null;
    }

    return window.localStorage.setItem(key, stringify(value));
};
