/* eslint-disable func-style */
import { useState, useEffect } from "react";
export function useAnimation(textsToDisplay, delay) {
    // State and setters for debounced value
    const [wordIndex, setWordIndex] = useState(0);
    const [placeholder, setPlaceholder] = useState(textsToDisplay[wordIndex].slice(0, 0));
    const [placeholderIndex, setPlaceholderIndex] = useState(0);

    useEffect(() => {
        const intr = window.setInterval(() => {
            setPlaceholder(textsToDisplay[wordIndex].slice(0, placeholderIndex));
            if (placeholderIndex + 1 > textsToDisplay[wordIndex].length) {
                setWordIndex((index) => (index + 1) % textsToDisplay.length);
                setPlaceholderIndex(0);
            } else {
                setPlaceholderIndex(placeholderIndex + 1);
            }
        }, delay);
        return () => {
            window.clearInterval(intr);
        };
    });
    return placeholder;
}
