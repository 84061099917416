import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";
import { SUPER_APP_PAGE_TYPES } from "../constants/super-app-constants";

/* eslint-disable max-statements */
const superAppService = (api) => {

    const getSuperAppHomeData = ({
        name = SUPER_APP_PAGE_TYPES.SUPER_WEB_HOME_PAGE,
        clientId,
        statsigStableId,
        source,
        userId,
        ...params
    }) => {
        const version = source === "mSite" ? "v2" : "v1";
        return api.get(`/api/${version}/bff-schema/super-web-pages/data?name=${name}`, {
            headers: {
                ...(clientId && { clientId }),
                ...(userId && {userId}),
                source,
                ...(statsigStableId && {x_experiment_id: statsigStableId})
            },
            params
        });
    };
    const fetchAdsDetails = ({slotName, platformType, bu, url }) => {
        return api.get(`/api/v1/bff-schema/consumer-api/ad`, { params: {slotName, platformType, bu, url, expand: "YES"}});
    };

    const getAqiData = (coordinates) => {
        const {lat, lng} = coordinates;
        return api.get(`/api/v1/aqi/geo/${lng}/${lat}`);
    };

    return {
        getSuperAppHomeData,
        fetchAdsDetails,
        getAqiData
    };
};

export const SuperAppService = superAppService(middlewareConfig(MIDDLEWARE_ENUMS.SUPER_APP_SERVICE));
