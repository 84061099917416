export default (carsDataList = []) => {
    const tabsData = {};
    const tabsDisplayList = [];

    (carsDataList || []).map((item, id) => {
        const { key, content, title } = item || {};
        if (content && content.length) {
            tabsData[key] = item;
            tabsDisplayList.push({
                title,
                disabled: false,
                key,
                id
            });
        }
    });

    return {tabsData, tabsDisplayList};

};
