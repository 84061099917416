/* eslint-disable max-statements */
import { appUrl } from "../../constants/url-constants";
import qs from "querystring";
import { DEFAULT_C2C_CITY } from "../../constants/app-constants";
import isObjectEmpty from "../../utils/helpers/isObjectEmpty";
import parseCookie from "../helpers/parse-cookie";

class Filters {
    constructor({ city, cityID, pincode }) {
        const cookieCityId = parseCookie("user_selected_city");
        this._city = city;
        this._cityID = (cookieCityId && cookieCityId !== cityID) ? cookieCityId : cityID || DEFAULT_C2C_CITY.city_id;
        this._defaultCity = DEFAULT_C2C_CITY.city_slug;
        this._pincode = pincode || "";
    }

    getListingUrl({ filters, options, isIndiaPage, seoLink }) {
        const payload = [];
        if (filters && filters.length) {
            const filter = typeof filters === "string" ? [filters] : filters;
            filter.map((f) =>  payload.push(qs.stringify({ f })));
            // payload.push(`f=${filter.join("|")}`);
        }
        if (options && typeof options === "object" && !isObjectEmpty(options)) {
            const o = Object.fromEntries(
                Object.entries(options)
                    .filter(([key, value]) => key !== "gaId" && value) // Filter out gaId and non-truthy values
            );
            payload.push(qs.stringify(o));
        }
        if (!isIndiaPage) {
            const cityPayload = qs.stringify({
                storeCityId: this._cityID
            });
            payload.push(cityPayload);
        }
        const payloadString = payload.join("&");
        if (seoLink && !seoLink.startsWith("/")) {
            seoLink = `/${seoLink}`;
        }
        return {
            relativeURL: `/buy-used-car?${payloadString}`,
            payload: payloadString,
            absoluteURL: `${appUrl()}/buy-used-car?${payloadString}`,
            seoURL: `${seoLink}?${payloadString}`
        };
    }

    getListingUrlWithoutCity({ value }) {
        value = value.toLowerCase().split(" ").join("-");
        return ["buy", "used", value, "cars"].join("-");
    }

    getListingUrlWithPreBuildParams({url, isIndiaPage, options}) {
        const payload = [];
        if (options && typeof options === "object" && !isObjectEmpty(options)) {
            const o = Object.fromEntries(
                Object.entries(options).filter(([key, value]) => key !== "gaId" && value)
            );
            payload.push(qs.stringify(o));
        }

        if (!isIndiaPage) {
            const cityPayload = qs.stringify({
                storeCityId: this._cityID
            });
            payload.push(cityPayload);
        }
        const payloadString = payload.join("&");
        return {
            relativeURL: `/${url}${url.includes("?") ? "&" : "?"}${payloadString}`,
            absoluteURL: `${appUrl()}/${url}&${payloadString}`
        };
    }

}

export default Filters;
