import React from "react";
import styles from "./styles.css";
import HeadingWithStroke from "../../shared/heading-with-stroke";
import PropTypes from "prop-types";
import getFilteredMediaData from "../../../utils/helpers/get-filtered-media-data";
import { MEDIA_DEVICE_TYPES, MEDIA_TYPES } from "../../../constants/super-app-constants";
import { STROKE_VARIANT } from "../../shared/heading-with-stroke/constants";
import { InView } from "react-intersection-observer";
import { sendCustomGaEvents } from "../../../tracking";
import { offerViewed, offerClicked } from "../../../constants/tracking-constants/super-home-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { Swiper, SwiperSlider } from "../../shared/swiper";

const SuperWebDiscountSection = ({
    superApp,
    sectionKey,
    preApproved,
    platformLabel,
    onClickCustomHandler = () => { }
}) => {

    const { parsedData } = superApp || {};
    const { title, data: sectionData } = parsedData[sectionKey] || {};

    const onBannerClickHandler = async (params, bannerLabel) => {
        onClickCustomHandler(params);

        await yieldToMain();
        sendCustomGaEvents({
            ...offerClicked,
            eventLabel: `${bannerLabel} | ${platformLabel}`
        });
    };

    const onChangeViewHandler = (inView, bannerLabel) => {
        if (inView) {
            sendCustomGaEvents({
                ...offerViewed,
                eventLabel: `${bannerLabel} | ${platformLabel}`
            });
        }
    };

    const getBannerData = () => {
        const { content } = sectionData || {};
        let filteredBannerData = getFilteredMediaData(content, MEDIA_DEVICE_TYPES.DESKTOP);
        filteredBannerData = (filteredBannerData || []).filter((bannerItem) => {
            const { metaInfo, preApproved: userPreApprovedBanner } = bannerItem || {};
            const { showAll } = metaInfo || {};

            const bannerValidCheck = showAll || (preApproved && userPreApprovedBanner) || (!preApproved && !userPreApprovedBanner);
            if (bannerValidCheck) return bannerItem;
            return null;
        });
        // eslint-disable-next-line consistent-return
        const bannersList = filteredBannerData.map((bannerItem) => {

            const { key, media, action, bannerId } = bannerItem || {};
            const { url: bannerUrl, type } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { meta: actionMetaData } = (action || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { flowType, bannerLabel } = actionMetaData || {};
            if (type === MEDIA_TYPES.IMAGE) {
                return (
                    <SwiperSlider key={key}>
                        <InView
                            key={key}
                            threshold={1}
                            onChange={(inView) => onChangeViewHandler(inView, bannerLabel)}
                        >
                            <div styleName={"styles.banner"} >
                                <img
                                    src={`${bannerUrl}`}
                                    alt={bannerId || key}
                                    onClick={onBannerClickHandler.bind(null, { action, media, flowType }, bannerLabel)}
                                />
                            </div>
                        </InView>
                    </SwiperSlider>
                );
            }

        });

        return bannersList || [];
    };

    return (
        <React.Fragment>
            <div className={"customContainer"} styleName={"styles.section"}>
                <HeadingWithStroke text={title} variant={STROKE_VARIANT.BLUE}/>
                <div styleName={"styles.sliderWrap "}>
                    <Swiper hideArrow={true}>
                        {

                            getBannerData()
                        }
                    </Swiper>
                </div>
            </div>
        </React.Fragment>
    );

};

SuperWebDiscountSection.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    preApproved: PropTypes.bool,
    onClickCustomHandler: PropTypes.func,
    platformLabel: PropTypes.string
};

export default SuperWebDiscountSection;
