import { LMS_API_PATH } from "../constants/api-constants";
import {lmsLeadAuthKey} from "../constants/url-constants";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const {
    DEAL_PAYMENT_INFO,
    REQUEST_CALLBACK_LEAD
} = LMS_API_PATH;

const lmsApi = (api) => {

    const fetchDealPaymentInfo = (dealHash, params) => {
        return api.get(`${DEAL_PAYMENT_INFO}/${dealHash}/payment-info`, { params });
    };

    const requestCallbackLead = (params) => {
        return api.post(`${REQUEST_CALLBACK_LEAD}`, {}, {
            params,
            headers: {
                Authorization: lmsLeadAuthKey()
            }
        });
    };
    return {
        fetchDealPaymentInfo,
        requestCallbackLead
    };
};

export const LmsService = lmsApi(middlewareConfig(MIDDLEWARE_ENUMS.LMS_CONST));

