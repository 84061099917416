import React from "react";
import skeleton from "./skeleton.css";

const Skeleton = () => {
    return (
        <React.Fragment>
            <div styleName={"skeleton.bannerWrap"}  className="shimmer" />

            <div className={"customContainer"}>
                <div className="shimmer" styleName={"skeleton.ourServices skeleton.section"} >
                    <div className="shimmer" styleName={"skeleton.services"} />
                    <div className="shimmer" styleName={"skeleton.searchWrap"} />
                    <div className="shimmer" styleName={"skeleton.barndList"} />
                </div>

                <div className="shimmer" styleName={"skeleton.discountHeading"} />

                <div styleName={"skeleton.discount skeleton.section"}>
                    <div className="shimmer" styleName={"skeleton.discountCard"} />
                    <div className="shimmer" styleName={"skeleton.discountCard"} />
                    <div className="shimmer" styleName={"skeleton.discountCard"} />
                    <div className="shimmer" styleName={"skeleton.discountCard"} />
                </div>

                <div className="shimmer" styleName={"skeleton.appBanner skeleton.section"} />

            </div>

        </React.Fragment>
    );
};

export default Skeleton;
