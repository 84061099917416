import { FOURDOOR_SOURCE } from "../../../constants/app-constants";

export const LANGUAGES = {
    HINDI: "Hindi",
    ENGLISH: "English",
    TAMIL: "Tamil",
    TELUGU: "Telugu",
    KANNADA: "Kannada",
    MALYALAM: "Malayalam"
};

export const LANGUAGE_LIST = [
    LANGUAGES.HINDI,
    LANGUAGES.ENGLISH,
    LANGUAGES.TAMIL,
    LANGUAGES.TELUGU,
    LANGUAGES.KANNADA,
    LANGUAGES.MALYALAM
];

export const LANGUAGE_OPTIONS = LANGUAGE_LIST.map((item) => ({label: item, value: item, ...(item === LANGUAGES.HINDI && {isDefault: true})}));

export const LEAD_SOURCE = [FOURDOOR_SOURCE.DESKTOP];

