/* eslint-disable complexity, max-statements, no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { InView } from "react-intersection-observer";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { SUPER_WEB_PAGE_LAYOUT } from "./helper";
import { SORT_TYPES_V2 } from "../../../constants/sort-types";
import Filters from "../../../utils/filters-v3";
import { LISTING_PAGE_LANDING_SOURCE } from "../car-list/constants";
// import { FORM_SOURCE } from "../interest-form/constants";
import saveCookie from "../../../utils/helpers/save-cookie";
import LoginSignup from "../login-signup-v2";
import { FORM_SOURCE } from "../interest-form/constants";
import { COOKIES_KEY_NAMES } from "../../../constants/cookie-keys";
import { getListFromCookie } from "../../../utils/helpers/store-list-in-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { CF_STATUS } from "../../../constants/cf-constants";
import { getCarListPayload } from "../../../utils/helpers/get-pa-offer-terms";
import { CUSTOM_FLOW_TYPES, DOWNLOAD_ICON_TYPES, MEDIA_DEVICE_TYPES, PLATFORM_TYPE } from "../../../constants/super-app-constants";
import { CMS_ACTION_TYPES } from "../../../constants/cms-constants";
import getBannerPopupContent from "../../../utils/helpers/get-banner-popup-content";
import { BANNER_POPUP_PAGE } from "../banner-popup/constants";
const RequestCallbackModal = loadable(() => import("../request-callback-modal"));
const BannerPopup = loadable(() => import("../banner-popup"));
import useScrollTrigger from "../../../hooks/use-scroll-trigger";
import { LOCATION_PAGE_TYPE } from "../location-picker-popup/constant";
import { CTA_WITHOUT_PINCODE_PAGES } from "../../../constants/app-constants";
import useWishlistDesktop from "../../../hooks/use-wishlist.desktop";
import Skeleton from "./skeleton";
import useBlockScroll from "../../../hooks/use-block-scroll";
import useManualScrollRestoration from "../../../hooks/use-scroll-restoration";
import { sendCustomGaEvents } from "../../../tracking";
import { superHomeViewed } from "../../../constants/tracking-constants/super-home-events";
const DownloadAppQrNudge = loadable(() => import("../download-app-qr-nudge"));
import ErrorHandler from "../../shared/with-error-handler";
import { GATE_NAME } from "../../../utils/statsig-ab-utils/constant";
import useGate from "../../../hooks/use-statsig-gate";

const SuperAppComponent = ({
    filters,
    superAppDataList,
    cities,
    pincode,
    history,
    isLoggedIn,
    preApproved,
    isSSR,
    gaId,
    userId,
    statsigId,
    preApprovalScreenVariant,
    cfStatus,
    selectedOffer,
    bannerContent,
    isLoading,
    isCTAClickedWithoutPincode,
    parsedData,
    isQrVisible,
    cityInfo,
    updateSortOrderConnect = () => { },
    reloadCarListConnect = () => { },
    fetchSuperAppHomeDataConnect = () => { },
    setIsSSRConnect = () => { },
    setBannerPopupContentConnect = () => { },
    toggleLocationPickerConnect = () => { },
    locationPageTypeConnect = () => { },
    setIsCTAClickedWithoutPincodeConnect = () => { }
}) => {
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 20 });
    const { data: filterData } = filters || {};
    const { selectedCity } = cities || {};
    const { seo_link: seoLinkUrl } = cityInfo || {};
    const { id: selectedCityId } = selectedCity || {};
    const [showLogin, setShowLogin] = useState(false);
    const [showCallbackModal, setShowCallBackModal] = useState(false);
    const [redirectQuery, setRedirectQuery] = useState(null);
    const [entryCfLabel, setEntryCfLabel] = useState(FORM_SOURCE.SUPER_APP_HOME);
    const [showQrCode, setShowQrCode] = useState(false);
    const platformLabel = PLATFORM_TYPE[MEDIA_DEVICE_TYPES.DESKTOP];

    useGate({ gateKeys: [GATE_NAME.skip_home_test_drive_gate,
        GATE_NAME.tax_visibility_tof]});
    useWishlistDesktop();
    useBlockScroll(isLoading);

    // useResetScrollPosition();

    useManualScrollRestoration();

    useEffect(() => {
        if (!pincode && trigger) {
            toggleLocationPickerConnect(true);
            locationPageTypeConnect(LOCATION_PAGE_TYPE.homePage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    useEffect(() => {
        sendCustomGaEvents({
            ...superHomeViewed,
            eventLabel: platformLabel
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isSSR) {
            const carIds = getListFromCookie(COOKIES_KEY_NAMES.RECENTLY_VIEWED);
            const statsigStableId = parseCookie("statsigStableId");
            const validStatsigId = !statsigStableId ? statsigId : statsigStableId;
            const financePayload = getCarListPayload(preApprovalScreenVariant, selectedOffer);
            fetchSuperAppHomeDataConnect({
                serviceType: "WEB",
                source: "WebApp",
                clientId: gaId,
                userId,
                cityId: selectedCityId,
                appIds: carIds && carIds.length ? carIds.join(",") : "",
                statsigStableId: validStatsigId,
                ...(preApproved && cfStatus !== CF_STATUS.DISCARDED && financePayload)
            });
        } else if (isSSR) {
            setIsSSRConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCityId]);

    useEffect(() => {
        const qrCookie = parseCookie(COOKIES_KEY_NAMES.DOWNLOAD_QR);
        if (!qrCookie) {
            setShowQrCode(true);
        }
    }, []);

    const redirectToListingPageByQuery = ({ query, options = {}, seoLink, seoOptimalUrl }) => {
        if (!pincode) {
            toggleLocationPickerConnect(true);
            locationPageTypeConnect(LOCATION_PAGE_TYPE.homePage);
            setIsCTAClickedWithoutPincodeConnect({
                type: query ? CTA_WITHOUT_PINCODE_PAGES.HOMEPAGE_DISCOUNT_BANNER : CTA_WITHOUT_PINCODE_PAGES.HOME_PAGE_VIEW_ALL_CARS,
                meta: {
                    query,
                    seoLink
                }
            });
            return;
        }
        updateSortOrderConnect(SORT_TYPES_V2.bestMatch.items.default.payload);
        let url = "";
        let listingUrl = "";
        const appliedFilters = new Filters({ cityID: selectedCityId, pincode });
        if (!seoOptimalUrl) {
            url = appliedFilters.getListingUrl({
                ...(query && !seoLink && { filters: query }),
                options: {
                    ...options,
                    listingSource: query ? LISTING_PAGE_LANDING_SOURCE.Search_HP : LISTING_PAGE_LANDING_SOURCE.ViewAllCars
                },
                seoLink
            });
            listingUrl = seoLink ? url.seoURL : url.relativeURL;
        } else {
            url = appliedFilters.getListingUrlWithPreBuildParams({
                url: seoOptimalUrl.url,
                options: {
                    ...options,
                    listingSource: query ? LISTING_PAGE_LANDING_SOURCE.Search_HP : LISTING_PAGE_LANDING_SOURCE.ViewAllCars
                },
                seoLink
            });
            listingUrl = url.relativeURL;
        }
        history.push(listingUrl);
        reloadCarListConnect(true);
        // if (!query) {
        //     sendCustomGaEvents({
        //         ...onclickViewAll,
        //         ...dimensionObject
        //     });
        // }
    };

    const onClickViewAllCars = () => {
        redirectToListingPageByQuery({ seoLink: seoLinkUrl });
    };

    const onClickEmiLoginCallback = (query) => {
        if (preApproved && query) {
            setRedirectQuery(null);
            redirectToListingPageByQuery({ query });
        } else {
            history.push(`/finance/personal-details?redirectTo=${window.location.pathname}&cfSource=${entryCfLabel}`);
        }

    };

    const onClickEmiFlow = ({ query, cfLabel }) => {
        // offerOnClickCallback();
        setRedirectQuery(query);
        setEntryCfLabel(cfLabel);
        if (!isLoggedIn) {
            // setToLocalStorage("cfSourceLabel", FORM_SOURCE.HOME_PAGE);
            saveCookie("cfSourceLabel", cfLabel);
            setShowLogin(true);
        } else {
            onClickEmiLoginCallback(query);
        }
        // else if (query) {
        //     //
        // }
        // else {
        //     history.push(`/finance/personal-details?redirectTo=${window.location.pathname}&cfSource=${cfLabel}`);
        // }
    };

    const onClickOpenWhatsApp = async ({ url: whatsAppUrl }) => {
        // const whatsappUrl = getWhatsAppUrl();
        await yieldToMain();
        window.open(whatsAppUrl);
    };

    const onClickCallBackRequest = async () => {
        setShowCallBackModal(true);
        await yieldToMain();
    };

    const onClickAppDownloadRedirect = ({ deviceOs, downloadUrls = {} }) => {
        const currentOS = deviceOs && DOWNLOAD_ICON_TYPES[deviceOs] ? deviceOs : DOWNLOAD_ICON_TYPES.ANDROID;
        const url = downloadUrls[currentOS] || "";
        if (url) {
            window.open(url, "_blank");
        }
    };

    const getOnClickCallback = ({ flowType, ...params }) => {
        switch (flowType) {
        case CUSTOM_FLOW_TYPES.BUY:
            return onClickViewAllCars.bind(null);
        case CUSTOM_FLOW_TYPES.BUY_FILTER:
            return redirectToListingPageByQuery.bind(null, params);
        case CUSTOM_FLOW_TYPES.EMI_DISCOUNT:
        case CUSTOM_FLOW_TYPES.EMI:
            return onClickEmiFlow.bind(null, params);
        case CUSTOM_FLOW_TYPES.WHATSAPP:
            return onClickOpenWhatsApp.bind(null, params);
        case CUSTOM_FLOW_TYPES.CALLBACK:
            return onClickCallBackRequest.bind(null, params);
        case CUSTOM_FLOW_TYPES.APP_NUDGE:
            return onClickAppDownloadRedirect.bind(null, params);
        default:
            return () => "";
        }
    };

    const onClickCustomHandler = ({
        action = {},
        media = {},
        flowType,
        deviceType = MEDIA_DEVICE_TYPES.DESKTOP,
        deviceOs
    }) => {

        const actionData = action[deviceType] || {};
        // const mediaData = media[deviceType] || {};
        const { actionType, url, meta: actionMetaData, query } = actionData || {};
        const { enableClickFlow, target = "_self" } = actionMetaData || {};

        const onClickCallback = getOnClickCallback({ flowType, query, url, deviceOs, ...actionMetaData });

        if (enableClickFlow) {
            onClickCallback();
            return;
        }

        if (actionType === CMS_ACTION_TYPES.NAVIGATE) {
            window.open(url, target || "_self");

        } else if (actionType === CMS_ACTION_TYPES.POPUP) {
            const popupContent = getBannerPopupContent({ action, media });
            setBannerPopupContentConnect({
                isOpen: true,
                bannerData: {
                    ...popupContent,
                    ctaClick: onClickCallback
                },
                pageType: BANNER_POPUP_PAGE.HOME
            });

        }
    };

    useEffect(() => {
        if (isCTAClickedWithoutPincode && isCTAClickedWithoutPincode.type === CTA_WITHOUT_PINCODE_PAGES.HOME_PAGE_VIEW_ALL_CARS && pincode) {
            onClickViewAllCars();
            setIsCTAClickedWithoutPincodeConnect({ type: "", meta: {} });
        } else if (isCTAClickedWithoutPincode && isCTAClickedWithoutPincode.type === CTA_WITHOUT_PINCODE_PAGES.HOMEPAGE_DISCOUNT_BANNER && pincode) {
            redirectToListingPageByQuery({ query: isCTAClickedWithoutPincode?.meta?.query, seoLink: isCTAClickedWithoutPincode?.meta?.seoLink });
            setIsCTAClickedWithoutPincodeConnect({ type: "", meta: {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCityId && selectedCityId.toString()]);

    const loginProps = {
        isVisible: showLogin,
        onClose: () => setShowLogin(false),
        heading: "Log in to check your loan eligibility",
        onLoginSuccessCallback: () => onClickEmiLoginCallback(redirectQuery),
        source: "Homepage"
    };

    const onCloseQrNudge = () => {
        setShowQrCode(false);
        saveCookie(COOKIES_KEY_NAMES.DOWNLOAD_QR, true);
    };

    return (<React.Fragment>
        {
            isLoading ? <Skeleton /> :
                (superAppDataList && !!superAppDataList.length) ? (
                    <LoginSignup {...loginProps}>
                        {
                            (superAppDataList || []).map((superAppSection, idx) => {
                                const { key: sectionKey } = superAppSection || {};
                                const Component = SUPER_WEB_PAGE_LAYOUT[sectionKey];
                                if (Component && parsedData && parsedData[sectionKey]) {
                                    return (
                                        <InView
                                            key={idx}
                                            id={sectionKey}
                                        // onChange={(inView) => onChangeScrollHandler(inView, sectionKey)}
                                        >
                                            <ErrorHandler>
                                                <Component
                                                    sectionKey={sectionKey}
                                                    filterData={filterData}
                                                    onClickCustomHandler={onClickCustomHandler}
                                                    onClickViewAllCars={onClickViewAllCars}
                                                    redirectToListingPageByQuery={redirectToListingPageByQuery}
                                                    showAnimation={true}
                                                    platformLabel={platformLabel}

                                                />
                                            </ErrorHandler>
                                        </InView>
                                    );
                                }
                                return null;
                            })
                        }
                        {
                            showCallbackModal &&
                            <RequestCallbackModal
                                showCallbackModal={showCallbackModal}
                                setShowCallBackModal={setShowCallBackModal}
                            />
                        }

                        {(bannerContent && bannerContent.isOpen) && <BannerPopup />}

                    </LoginSignup>
                ) : (<Skeleton />)
        }
        {showQrCode && <DownloadAppQrNudge isQrVisible={isQrVisible} onClose={onCloseQrNudge} />}
    </React.Fragment>);
};

SuperAppComponent.propTypes = {
    superAppDataList: PropTypes.array,
    filters: PropTypes.object,
    cities: PropTypes.object,
    pincode: PropTypes.string,
    history: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    preApproved: PropTypes.bool,
    isSSR: PropTypes.bool,
    gaId: PropTypes.string,
    userId: PropTypes.string,
    statsigId: PropTypes.string,
    cfStatus: PropTypes.string,
    selectedOffer: PropTypes.object,
    bannerContent: PropTypes.object,
    isLoading: PropTypes.bool,
    parsedData: PropTypes.object,
    preApprovalScreenVariant: PropTypes.string,
    isCTAClickedWithoutPincode: PropTypes.object,
    updateSortOrderConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    setIsSSRConnect: PropTypes.func,
    fetchSuperAppHomeDataConnect: PropTypes.func,
    setBannerPopupContentConnect: PropTypes.func,
    toggleLocationPickerConnect: PropTypes.func,
    locationPageTypeConnect: PropTypes.func,
    setIsCTAClickedWithoutPincodeConnect: PropTypes.func,
    isQrVisible: PropTypes.bool,
    cityInfo: PropTypes.object
};

export default SuperAppComponent;
