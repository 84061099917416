import {
  hamburgerCarScrap,
  hamburgerCarServicing,
  hamburgerChallan,
  hamburgerCheckVehicleDetails,
  hamburgerServiceHistory
} from "./tracking-constants/car-services";
export const CAR_SERVICE_URLS = [
  {
    title: "View & Pay Challan",
    seoLink: "traffic-challan/",
    event: hamburgerChallan
  },
  {
    title: "Vehicle History Report",
    seoLink: "car-service-history/",
    event: hamburgerServiceHistory
  },
  {
    title: "Car Servicing & Repair",
    seoLink: "",
    customUrl: "https://www.fourdoor.com/",
    event: hamburgerCarServicing
  },
  {
    title: "Car Scrap",
    seoLink: "scrap-cars/",
    event: hamburgerCarScrap
  },
  {
    title: "Check vehicle details",
    seoLink: "rto-vehicle-registration-details/",
    event: hamburgerCheckVehicleDetails
  },
  {
    title: "Hire a driver",
    seoLink: "",
    customUrl: "https://www.autopilotcars24.com/",
    event: null
  },
  {
    title: "Pre delivery Inspection",
    seoLink: "pre-delivery-inspection/",
    event: null
  },
  {
    title: "Driving School",
    seoLink: "driving-school/",
    event: null
  }
];
