import React, {Fragment} from "react";
import skeleton from "./skeleton.css";

const Skeleton = () => {
    return (
        <Fragment>
            <div className={"media align-items-center"}>
                {/* <div className="shimmer" styleName={"skeleton.icon"} /> */}
                <div className={"media-body"}>
                    <div className="shimmer" styleName={"skeleton.userName"} />
                    <div className="shimmer" styleName={"skeleton.account"} />
                </div>
            </div>
        </Fragment>
    );
};

export default Skeleton;
