import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Whatsapp from "../../shared/images/nedd-help-icon/whatsapp.svg";
import headPhone from "../../shared/images/nedd-help-icon/head-phone.svg";
import Faq from "../../shared/images/nedd-help-icon/faq.svg";
import rightArrow from "../../shared/images/nedd-help-icon/right-arrow.svg";
import { withRouter } from "react-router-dom";
import LazyImage from "../../shared/lazy-image";
import CloseButton from "./images/close-icon.svg";
import { sendCustomGaEvents } from "../../../tracking";
import { needHelpOnCLick } from "./tracking";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import { routeToEventCategoryMapping } from "../../../constants/app-constants";

const HelpSupportModal = ({
    setShowCallBackModal,
    modalStatus,
    history,
    setModalSourceConnect,
    isLoggedIn,
    cityId,
    cityName,
    userId,
    route
}) => {

    const dimensionObject = getGaEventDimensionObject({
        isLoggedIn,
        cityId,
        cityName,
        userId
    });
    const onClickOpenWhatsApp = () => {
        const whatsappUrl = "http://bit.ly/3XzKHQ1";
        window.open(whatsappUrl);
        setModalSourceConnect(false);
        sendCustomGaEvents({
            ...needHelpOnCLick,
            eventLabel: "whatsapp",
            eventCategory: routeToEventCategoryMapping[route.name],
            ...dimensionObject
        });
    };

    const onClickCallBackRequest = () => {
        setShowCallBackModal(true);
        sendCustomGaEvents({
            ...needHelpOnCLick,
            eventLabel: "callback",
            eventCategory: routeToEventCategoryMapping[route.name],
            ...dimensionObject
        });
    };
    const onClickFaq = () => {
        setModalSourceConnect(false);
        history.push("/faq#buy");
        sendCustomGaEvents({
            ...needHelpOnCLick,
            eventLabel: "faq",
            eventCategory: routeToEventCategoryMapping[route.name],
            ...dimensionObject
        });
    };

    const handleOnClick = () => {
        setModalSourceConnect(false);
    };

    return (
        <React.Fragment>
            {modalStatus && <div styleName={"styles.helpModalWrap"}>
                <div styleName={"styles.headingWraper"}>
                    <h4>Help & Support</h4>
                    <LazyImage onClick={handleOnClick} src={CloseButton} alt="close" />
                </div>

                <div onClick={onClickOpenWhatsApp} className={"media"} styleName={"styles.cardWrap"}>
                    <span styleName={"styles.icon"}><img src={Whatsapp} alt="Ask us on Whatsapp" /> </span>
                    <div className={"media-body"}>
                        <h6 styleName={"styles.heading"}>Ask us on WhatsApp!</h6>
                        <p>
                            <span>Get instant support via experts</span>
                            <img src={rightArrow} alt="Right Arrow" />
                        </p>
                    </div>
                </div>

                <div onClick={onClickCallBackRequest} className={"media"} styleName={"styles.cardWrap"}>
                    <span styleName={"styles.icon"}><img src={headPhone} alt={""} /> </span>
                    <div className={"media-body"}>
                        <h6 styleName={"styles.heading"}>Request a call back</h6>
                        <p>
                            <span>Our team will contact you shortly!</span>
                            <img src={rightArrow} alt="Right Arrow" />
                        </p>
                    </div>
                </div>

                <div onClick={onClickFaq} className={"media mb-0"} styleName={"styles.cardWrap"}>
                    <span styleName={"styles.icon"}><img src={Faq} alt={""} /> </span>
                    <div className={"media-body"}>
                        <h6 styleName={"styles.heading"}>View FAQ’s</h6>
                        <p>
                            <span>Find answers to FAQ’s</span>
                            <img src={rightArrow} alt="Right Arrow" />
                        </p>
                    </div>
                </div>
            </div>}

        </React.Fragment>
    );
};
HelpSupportModal.propTypes = {
    setShowCallBackModal: PropTypes.func,
    modalStatus: PropTypes.bool,
    history: PropTypes.object,
    setModalSourceConnect: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    cityId: PropTypes.number,
    cityName: PropTypes.string,
    userId: PropTypes.number,
    route: PropTypes.object
};

export default withRouter(HelpSupportModal);
