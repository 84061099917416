import { NUMBER} from "../../constants/app-constants";

export default (uspDetailsList = [], deviceKey) => {
    return (uspDetailsList || []).slice(NUMBER.ZERO, NUMBER.THREE).map((uspItem) => {
        const { name, icon } = uspItem || {};
        const { url } = (icon || {})[deviceKey] || {};
        return {
            uspLabel: name,
            iconUrl: url
        };
    });
};
