import Types from "./types";
import { SuperAppService } from "../../../service/super-app-service";
import parseSuperAppHomeData from "../../../utils/helpers/parse-super-app-home-data";
import { SUPER_APP_SECTION_TYPES } from "../../../constants/super-app-constants";
import { fetchFilterSuccess } from "../filter-v2/action";

const setIsDataLoading = (data) => ({
    type: Types.IS_DATA_LOADING,
    data
});

const setIsSSR = (data) => ({
    type: Types.SET_IS_SSR,
    data
});

const setIsQrVisible = (data) => ({
    type: Types.IS_QR_VISIBLE,
    data
});

const setSuperAppHomeDataInit = () => ({
    type: Types.SET_SUPER_APP_HOME_DATA_INIT
});

const setSuperAppHomeDataSuccess = (data) => ({
    type: Types.SET_SUPER_APP_HOME_DATA_SUCCESS,
    data
});

const setSuperAppHomeDataFailure = (data) => ({
    type: Types.SET_SUPER_APP_HOME_DATA_FAILURE,
    data
});

const fetchSuperAppHomeData = (params) => (dispatch) => {
    dispatch(setSuperAppHomeDataInit());
    return new Promise((resolve, reject) => {
        SuperAppService.getSuperAppHomeData(params).then((response) => {
            const { data: superAppResponseData } = response.data || {};
            const { data: superAppDataList = [] } = superAppResponseData || {};
            const { parsedData, dataList } = parseSuperAppHomeData(superAppDataList) || {};
            const { data: facetsDataSection } = parsedData[SUPER_APP_SECTION_TYPES.SUPER_WEB_CARS_BY_CATEGORY] || {};

            dispatch(fetchFilterSuccess((facetsDataSection || {}).content || {}));
            dispatch(setSuperAppHomeDataSuccess({
                dataList,
                parsedData
            }));
            resolve(response.data);
        }).catch((error) => {
            dispatch(setSuperAppHomeDataFailure(error));
            reject(error);
        });
    });
};

const setBannerPopupContent = (data) => ({
    type: Types.SET_BANNER_POPUP_CONTENT,
    data
});

export {
    setIsDataLoading,
    fetchSuperAppHomeData,
    setIsSSR,
    setBannerPopupContent,
    setIsQrVisible
};
