import React from "react";
import ErrorHandler from "../../../components/shared/with-error-handler";
import SuperAppComponent from "../../../components/desktop/super-app";
import MetaTagsSuperWebHome from "../../../components/shared/meta-tags-super-web-home";

const SuperAppHome = () => {
    return (
        <ErrorHandler>
            <MetaTagsSuperWebHome />
            <SuperAppComponent/>
        </ErrorHandler>
    );
};

export default SuperAppHome;
