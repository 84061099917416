import Types from "./types";

import { CatalogGatewayService } from "../../../service/catalog-gateway";
import { fetchPresetFiltersSuccess } from "../buy-used-car/actions";

const fetchPresetFilters = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getPresetFilters(params)
            .then(response => {
                dispatch(fetchPresetFiltersSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const setHeaderVisibility = (headerVisibility) => ({
    type: Types.SET_HEADER_VISIBILITY,
    headerVisibility
});

const setBuyCarsHeaderData = (data) => ({
    type: Types.SET_BUY_CARS_HEADER_DATA,
    data
});

const updateSSR = (isSSR) => ({
    type: Types.UPDATE_SSR,
    isSSR
});

const fetchBuyCarsHeaderData = (cityId) => dispatch => {
    return new Promise((resolve, reject) => {
        const modifiedCityId = cityId ?? "";
        CatalogGatewayService.fetchHeaderBuyCars(modifiedCityId).then(response => {
            const { data: responseData } = response;
            const { data } = responseData || {};
            dispatch(setBuyCarsHeaderData(data));
            resolve(response);
        })
            .catch(error => {
                reject(error);
            });
    });
};

export {
    fetchPresetFilters,
    setHeaderVisibility,
    updateSSR,
    fetchBuyCarsHeaderData
};
