export const LOGIN_SOURCE = {
    DETAIL: "Detail",
    HEADER: "Header",
    HOME_PAGE: "Homepage",
    LISTING: "Listing",
    SELLER: "Seller",
    WISHLIST_HEADER: "Wishlist_header",
    WISHLIST_CAR_CARD: "Wishlist_car_card",
    WISHLIST_CAR_DETAIL: "Wishlist_cardetail",
    DEEPLINK: "Deeplink",
    LOAN_PAGE: "Loan_page",
    CF_FORM: "CF_Form",
    CHECKOUT: "Checkout"
};

export const LOGIN_POPUP_HEADING = {
    LOGIN_TO_VIEW_YOUR_SELECTIONS: "Log in to view your selections",
    LOGIN_TO_BOOK_TEST_DRIVE: "Log in to conveniently book a test drive",
    LOGIN_TO_CHECK_ELIGIGBILITY: "Log in to check your loan eligibility",
    LOGIN_TO_GET_SERVICE_HISTORY_REPORT: "Log in to get service history report",
    LOGIN_TO_VIEW_BOOKINGS: "Log in to view your bookings",
    SIGNIN_TO_SEE_WISHLIST: "Sign in to see your Wishlist",
    SIGNIN_TO_WISHLIST: "Sign in to Wishlist",
    LOGIN_TO_CHECK_SERVICE_HISTORY_REPORT: "Log in to check service history report",
    LOGIN_TO_SET_NOTIFICATION_ALERT: "Log in to set Notification Alert"
};

export const NETWORK_ERROR_CONSTANTS = {
    NETWORK_ERROR: "network error",
    INTERNET_ISSUE: "Uh oh! We're having trouble connecting to the internet. Please check and try again"
};
