import React, { useEffect, useCallback } from "react";
import styles from "./styles.css";
import searchIcon from "./images/search-icon.svg";
import PropTypes from "prop-types";

const MakeModalSearchList = ({
    searchSuggestions,
    searchInput,
    handleSearchSuggestionSelect,
    updateSearchSuggestionsOnArrowKeys,
    setSelectedIndex,
    selectedIndex
}) => {

    const highlightSearchResult = (searchResult) => {
        const smallSearchInput = searchInput.toLowerCase();
        const newResultText = searchResult.replace(new RegExp(smallSearchInput, "gi"), match => `<span>${match}</span>`);
        return newResultText;
    };

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, searchSuggestions.length - 1));
                updateSearchSuggestionsOnArrowKeys(searchSuggestions[selectedIndex + 1], selectedIndex + 1);
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
                updateSearchSuggestionsOnArrowKeys(searchSuggestions[selectedIndex - 1], selectedIndex - 1);
            } else if (e.key === "Enter" && selectedIndex >= 0) {
                handleSearchSuggestionSelect(searchSuggestions[selectedIndex], selectedIndex);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedIndex, updateSearchSuggestionsOnArrowKeys, searchSuggestions, handleSearchSuggestionSelect]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <div styleName={"styles.searchListWrap"}>
            <ul styleName={"styles.searchListItem"}>
                {searchSuggestions && searchSuggestions.map((option, index) => {
                    const { displayTitle } = option;
                    const isSelected = index === selectedIndex;
                    return (
                        <li key={index} styleName={isSelected ? "styles.highlighted" : ""} onClick={() => handleSearchSuggestionSelect(option, index)}>
                            <img src={searchIcon} alt="" />
                            <span dangerouslySetInnerHTML={{ __html: highlightSearchResult(displayTitle) }} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

MakeModalSearchList.propTypes = {
    searchSuggestions: PropTypes.array,
    searchInput: PropTypes.string,
    handleSearchSuggestionSelect: PropTypes.func,
    updateSearchSuggestionsOnArrowKeys: PropTypes.func,
    setSelectedIndex: PropTypes.func,
    selectedIndex: PropTypes.number
};

export default MakeModalSearchList;
