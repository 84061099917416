import { createTypes } from "../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_FILTERS
    FETCH_FILTERS_SUCCESS
    FETCH_FILTERS_FAILURE
    UPDATE_SSR_STATUS

    UPDATE_FILTER_TIMESTAMP

    UPDATE_SELECTED_ON_SCREEN_FILTER

    UPDATE_ALL_FILTERS_VISIBILITY
    UPDATE_FILTER
    UPDATE_FILTER_TAB_TYPE

    SHOW_FILTER_ON_MODAL
    UPDATE_SELECTED_SUGGESTIONS

    RESET_FILTERS
    RESET_CURRENT_FILTER
    CLEAR_SUGGESTION

    UPDATE_PRICE_FILTER_TAB
    CLEAR_ALL_MODAL
    UPDATE_SELECTED_FILTER

    SET_INDIA_PAGE
    SET_INDIA_PAGE_URL
    SET_FILTER_SEARCH_QUERY
`,
    {
        prefix: "filters/"
    }
);
