import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SuperWebServicesSection from "./component";
import { toggleLocationPicker, locationPageType, setIsCTAClickedWithoutPincode } from "../location-picker-popup/actions";
import { reloadCarList } from "../car-list/actions";
import { withRouter } from "react-router-dom";

const mapStateToProps = ({
    superApp,
    user: {
        preApproved
    },
    buyUsedCarHome: { popularBrands },
    carList: { appliedOptions },
    location: { pincode },
    locationPicker: { isCTAClickedWithoutPincode },
    cities,
    experiments: {
        statsigDynamicConfig: {
            data: statsigDynamicConfigData
        },
        websiteAdvertisementBanner: {
            data: websiteAdvertisementBannerData
        }
    }
    // config
}) => ({
    superApp,
    preApproved,
    popularBrands,
    appliedOptions,
    pincode,
    isCTAClickedWithoutPincode,
    cities,
    statsigDynamicConfigData,
    websiteAdvertisementBannerData
    // config
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    toggleLocationPickerConnect: toggleLocationPicker,
    locationPageTypeConnect: locationPageType,
    setIsCTAClickedWithoutPincodeConnect: setIsCTAClickedWithoutPincode,
    reloadCarListConnect: reloadCarList
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperWebServicesSection));
