import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    content: {},
    error: null
};

export const fetchFeaturedCarsCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, content: data };
};

export const fetchFeaturedCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error};
};

export const HANDLERS = {
    [Types.FETCH_FEATURED_CARS_SUCCESS]: fetchFeaturedCarsCarsSuccess,
    [Types.FETCH_FEATURED_CARS_FAILURE]: fetchFeaturedCarsFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
