import { CMS_ACTION_TYPES } from "../../constants/cms-constants";
import { MEDIA_DEVICE_TYPES } from "../../constants/super-app-constants";

export const getTransformedServiceLabel = (label) => (label || "").split(" ").join("_").toUpperCase();

export const parseServicesList = ({
    serviceList = [],
    // onClickTabHandler = () => {},
    deviceType
}) => {
    // let parsedServiceList = [...serviceList];
    const parsedServiceList = [];
    const serviceDataMap = {};

    (serviceList || []).forEach((serviceItem) => {

        const {
            iconImage,
            title,
            meta,
            redirectionUrl,
            actionType,
            offerIconImage
        } = serviceItem || {};
        const { url: iconUrl} = iconImage || {};
        const { isExclusive = false, enableClickFlow = false, flowType, query, isActive, target } = meta || {};
        const { name: actionName } = actionType || {};
        const clickParams = {
            flowType,
            action: {
                [deviceType]: {
                    actionType: actionName,
                    meta: {
                        url: redirectionUrl,
                        enableClickFlow,
                        target
                    },
                    query
                }

            }
        };

        const badgeIcon = offerIconImage?.url;

        const serviceTab = {
            iconUrl,
            label: title,
            withBorder: isExclusive,
            ...(badgeIcon && { badgeIcon }),
            ...((redirectionUrl && actionName === CMS_ACTION_TYPES.REDIRECT) && {
                asLink: true,
                href: redirectionUrl,
                target
            }),
            ...(deviceType === MEDIA_DEVICE_TYPES.DESKTOP && { isActive })

            // ...(enableClickFlow && {onClickHandler: onClickTabHandler.bind(null, clickParams)})
        };

        parsedServiceList.push(serviceTab);
        serviceDataMap[getTransformedServiceLabel(title)] = clickParams;
    });

    return { parsedServiceList, serviceDataMap };
};

export const disableActiveTabs = (serviceList) => {
    return (serviceList || []).map((item) => ({
        ...item,
        isActive: false,
        disableCrawl: true
    }));
};

