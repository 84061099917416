/* eslint-disable max-statements */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import InputOTP from "../../shared/input-otp";
import VerifyNumber from "./images/success-icon.svg";
import Button from "../../shared/button";
import CountdownTimer from "../../shared/countdown-timer";
import { sendCustomGaEvents } from "../../../tracking";
import routePageMap from "../../../utils/helpers/callback-tracking-map";
import Search from "../../shared/search";
import {LANGUAGE_OPTIONS} from "./constant";
import InputMobile from "../../shared/input-mobile";
import InputTextarea from "../../shared/input-textarea";
import { callbackRequestCtaClick } from "./tracking";
import { LEAD_SOURCE } from "./constant";
import { getOperationalTime } from "./utils";

const RequestCallbackSection = ({
    mobile,
    onMobileNoChange,
    onOtpChange,
    isNumberVerified,
    isOtpVerified,
    showTimer,
    isOtpVisible,
    onCompleteTimer,
    onResendClick,
    errorMessage,
    otpRef,
    leadConnect,
    otpVisibility,
    modalStatus,
    isLoggedIn,
    routeName,
    callbackSource,
    source
}) => {
    const [mobileNo, setmobileNo] = useState("");
    const [otp, setOtp] = useState("");
    const [callCta, setCallCta] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const defaultLang = LANGUAGE_OPTIONS.filter(item => item.isDefault)[0];
    const [selectedLang, setSelectedLang] = useState(defaultLang);
    const [textArea, setTextArea] = useState("");

    const onEnteredMobileNo = ({ target: { value } }) => {
        setmobileNo(value);
        onMobileNoChange(value);
    };

    const onEnteredOtp = ({ target: { value } }) => {
        setOtp(value);
        onOtpChange(value, mobileNo);
    };
    useEffect(() => {
        setmobileNo(mobile || "");
    }, [mobile]);

    const onSubmit = () => {
        const params = {
            sourceLocation: callbackSource || routePageMap[routeName] || "",
            primaryPhone: mobileNo,
            text: textArea,
            preferredLanguage: selectedLang.value,
            ...(LEAD_SOURCE.includes(source) && ({ source }))
        };
        setIsLoading(true);
        leadConnect(params)
            .then(() => {
                setIsLoading(false);
                otpVisibility(false);
                setCallCta(false);
            }).catch(() => {
                setIsLoading(false);
                otpVisibility(false);
                setCallCta(true);
            });
        sendCustomGaEvents({
            ...callbackRequestCtaClick,
            eventLabel: routePageMap[routeName]
        });
    };

    const onChangeLanguage = (lang) => {
        setSelectedLang(lang);
    };
    const onChangeText = ({ target: { value } }) => {
        setTextArea(value);
    };

    useEffect(() => {
        if (isLoggedIn) {
            modalStatus(true);
        } else {
            modalStatus(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        return () => {
            setmobileNo(mobile || "");
            setOtp("");
            setCallCta(true);
            otpVisibility(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <h4 styleName={"styles.heading"}>Phone Number</h4>
            <div styleName={"styles.inputNumber"}>
                <InputMobile
                    value={mobileNo}
                    placeholder="Mobile Number"
                    type={"number"}
                    onChange={onEnteredMobileNo}
                    disabled={isOtpVerified}
                />
                {isNumberVerified && <img src={VerifyNumber} />}
            </div>

            {isOtpVisible && <div styleName={"styles.inputOtp"}>
                <InputOTP
                    inputRef={otpRef}
                    value={otp}
                    text="Enter OTP"
                    onChange={onEnteredOtp}
                />
                {
                    showTimer ? (
                        <div styleName={"styles.otpCounter"}>
                            <CountdownTimer interval={30} onComplete={onCompleteTimer} />
                        </div>

                    ) : (
                        <button onClick={() => onResendClick(mobileNo)} styleName={"styles.resendButtonOtp"}>Resend</button>
                    )
                }
                <span styleName={"styles.errorMsg"}>{errorMessage}</span>
            </div>}

            {/* Language dropdown */}
            <h4 styleName={"styles.heading"}>Language</h4>
            <div styleName={"styles.languageDropdownWrap"}>
                <Search
                    placeholder="Select your preferred language"
                    data={LANGUAGE_OPTIONS}
                    onChange={onChangeLanguage}
                    value={selectedLang}
                    isSearchable={false}
                />
            </div>

            <div styleName={"styles.textareaWrapper"}>
                <InputTextarea
                    placeholder="Tell us what you need help with"
                    onChange={onChangeText}
                />
            </div>
            <p styleName={"styles.clickContent"}>
                {!callCta && <span>Thanks, for reaching out to us!</span>}
                {callCta ?
                    <span>
                        To have a CARS24 representative <br/>call you, please click below
                        <small styleName={"styles.operationalTime"}>We are operational between {getOperationalTime(source)}</small>
                    </span>
                    : <span>A CARS24 representative will call you <br/>shortly, please answer your phone</span>
                }
            </p>

            {callCta && <Button text={"CALL ME" } isLoading={isLoading} onClick={onSubmit} disabled={!isOtpVerified} />}

        </React.Fragment>
    );
};

RequestCallbackSection.propTypes = {
    onMobileNoChange: PropTypes.func,
    onOtpChange: PropTypes.func,
    onCompleteTimer: PropTypes.func,
    onResendClick: PropTypes.func,
    updateCallBackPopupStatusConnect: PropTypes.func,
    isNumberVerified: PropTypes.bool,
    isOtpVerified: PropTypes.bool,
    showTimer: PropTypes.bool,
    isOtpVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    mobile: PropTypes.string,
    otpRef: PropTypes.object,
    leadConnect: PropTypes.func,
    routeName: PropTypes.string,
    otpVisibility: PropTypes.func,
    modalStatus: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    callbackSource: PropTypes.string,
    source: PropTypes.string
};

export default RequestCallbackSection;
