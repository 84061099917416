import { findByKey } from "../../../utils/helpers/find-by-key";
import { updateCityLocationDetails } from "../../location/actions";
import Types from "./types";
import { sanitizeObject } from "../../../utils/helpers/sanitizers";
import { updateCurrentCityWithPicker } from "../../../utils/helpers/set-current-city";
import isObjectEmpty from "../../../utils/helpers/isObjectEmpty";
import { VehicleService } from "../../../service/vehicle-service";
import { selectCity } from "../cities/actions";

const toggleLocationPicker = (status, autoFocusSearch = false) => ({
    type: Types.TOGGLE_LOCATION_PICKER,
    status,
    autoFocusSearch
});

const setLocationSelectionType = (locationType) => ({
    type: Types.LOCATION_SELECTETION_TYPE,
    locationType
});

const toggleLocationPickerV2 = (status) => ({
    type: Types.TOGGLE_LOCATION_PICKER_V2,
    status
});

const locationPageType = (pageType) => ({
    type: Types.LOCATION_PAGE_TYPE,
    pageType
});

const setIsCTAClickedWithoutPincode = (isCTAClickedWithoutPincode) => ({
    type: Types.IS_CTA_CLICKED_WITHOUT_PINCODE,
    isCTAClickedWithoutPincode
});

const updateUserLocation = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { cityId } = data || {};
        const cityList = getState().config.cityList || [];
        const selectedUserCity = findByKey(cityList, "city_id", (cityId || "").toString());

        const handleCityUpdate = (cityData) => {
            resolve(cityData);
            dispatch(updateCityLocationDetails(cityData));
            updateCurrentCityWithPicker(
                sanitizeObject(cityData),
                () => dispatch(selectCity),
                true,
                cityData.pincode
            );
        };

        if (!isObjectEmpty(selectedUserCity)) {
            handleCityUpdate(selectedUserCity);
        } else {
            VehicleService.fetchCityByCityId(cityId).then((response) => {
                const cityData = response?.data?.detail;
                handleCityUpdate(cityData);
            }).catch((err) => {
                reject(err);
            });
        }
    });
};

export {
    toggleLocationPicker,
    toggleLocationPickerV2,
    setLocationSelectionType,
    locationPageType,
    setIsCTAClickedWithoutPincode,
    updateUserLocation
};
