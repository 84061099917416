import React from "react";
import { bindActionCreators } from "redux";
import withLayout from "../../../components/desktop/layout/component";
import SuperAppHome from "./component";
import withReducer from "../../../components/shared/with-reducer";
import injectable from "./injectable";

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withReducer(injectable)(mapStateToProps, mapDispatchToProps)(withLayout((SuperAppHome),
    true,
    true,
));
