/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { NUMBER } from "../../../constants/app-constants";
import CheckIcon from "./check-icon.json";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});

const InputMobile = ({
    onChange,
    inputRef,
    value = "",
    placeholder = "Mobile Number",
    hidePrefixIfNoValuePresent = false,
    showError = true,
    onFocusCallback = () => { },
    onBlurCallback = () => { },
    onClick = () => { },
    countryCode = "91",
    maxLength = "10",
    validator = undefined,
    isPrefixAsFixed = false,
    isLabelShift = true,
    rightImg = false,
    disabled = false,
    ...rest }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState("");
    const [isPrefixVisible, setIsPrefixVisible] = useState(true);

    const mobileValidator = validator || mobileNumberValidator; // defaults to India validator

    const onFocus = (e) => {
        const phone = (e.target.value || "");
        if (phone.length === NUMBER.TEN || phone.length === 0) {
            setIsFocused(true);
            setIsTouched(true);
        }
        setIsPrefixVisible(true);
        onFocusCallback();
    };
    const onBlur = (e) => {
        setIsFocused(false);
        if (hidePrefixIfNoValuePresent && !e.target.value.length) {
            setIsPrefixVisible(false);
        }
        onBlurCallback(e.target.value);
    };

    const onChangeHandler = (e) => {
        const regNumbers = /^\d{0,10}$/;
        let number;
        if (regNumbers.test(e.target.value) && !disabled) {
            number = e.target.value;
            if (isPrefixAsFixed) {
                if (!mobileValidator(number) && number) {
                    setHasError(true);
                    setError("Invalid mobile number");
                } else {
                    setHasError(false);
                    setError("");
                }
            } else if (!mobileValidator(number)) {
                setHasError(true);
                setError("Invalid mobile number");
            } else {
                setHasError(false);
                setError("");
            }
            onChange(e);
        }
    };

    return (

        <div>
            <div styleName={`${isLabelShift ? "styles.inputShift" : "styles.withoutInputShift"} styles.withPrefix`}>
                <input
                    ref={inputRef}
                    type="tel"
                    maxLength={maxLength}
                    name="phone"
                    className="form-control"
                    onFocus={(e) => onFocus(e)}
                    onBlur={(e) => onBlur(e)}
                    onClick={e => onClick(e)}
                    value={value}
                    onChange={onChangeHandler}
                    autoComplete={"off"}
                    disabled={disabled}
                    {...(!isLabelShift && {placeholder})}
                    {...rest}
                // required
                // autoComplete={"off"}
                />
                {isPrefixAsFixed ?
                    <React.Fragment>
                        <span id="prefix" styleName={"styles.phoneNumberPrefix"}>
                            +{countryCode}-
                        </span>
                    </React.Fragment>
                    : <React.Fragment>
                        {(value || isFocused || hasError) && isPrefixVisible &&
                            <span id="prefix" styleName={"styles.phoneNumberPrefix"}>
                                +{countryCode}
                            </span>
                        }
                    </React.Fragment>
                }
                {!isPrefixAsFixed && isLabelShift && <label
                    styleName={`${(value || isFocused || hasError) ? "active" : ""}`}>
                    {placeholder}
                </label>}
                {rightImg &&
                    <div styleName={"styles.rightImg"}>
                        <Lottie options={{ loop: false, autoplay: true, animationData: CheckIcon,
                            rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }} height={"100%"} width={"100%"} />
                    </div>
                }
            </div>
            {
                (showError && hasError && ((isTouched &&
                    !isFocused) || (value.length === NUMBER.TEN)))
                &&
                <small styleName={"styles.error"}>{error}</small>
            }
        </div>
    );
};

InputMobile.propTypes = {
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputRef: PropTypes.any,
    onBlurCallback: PropTypes.func,
    showError: PropTypes.bool,
    placeholder: PropTypes.string,
    onFocusCallback: PropTypes.func,
    onClick: PropTypes.func,
    hidePrefixIfNoValuePresent: PropTypes.bool,
    countryCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validator: PropTypes.func,
    isPrefixAsFixed: PropTypes.bool,
    isLabelShift: PropTypes.bool,
    rightImg: PropTypes.bool,
    disabled: PropTypes.bool
};

export default InputMobile;
