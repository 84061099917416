import { AuthService } from "../../../service/auth-service";

const sendOTP = (mobile) => () => {
    return new Promise((resolve, reject) => {
        AuthService.sendOTP(mobile)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const verifyOtp = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.verifyOTP(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const login = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.login(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export {
    sendOTP,
    verifyOtp,
    login
};
