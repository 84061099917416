import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SuperWebGetAppBannerWrapper from "./component";

const mapStateToProps = ({
    superApp
}) => ({
    superApp
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuperWebGetAppBannerWrapper);
