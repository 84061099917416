import React from "react";
import Statsig from "statsig-js";
import getMeaningfulExperimentNames from "../utils/statsig-ab-utils/get-meaningful-experiment-names";
import { useSelector, useDispatch } from "react-redux";
import { setExperiment as setExperimentConnect } from "../components/shared/ab-experiment/actions";

const useGate = ({ gateKeys }) => {
    const { experiments: allExperiments } = useSelector(store => store);
    const { statsigConfig } = allExperiments || {};
    const {statsigInitialized = false, initializedTimeStamp = ""} = statsigConfig || {};
    const dispatch = useDispatch();

    const getGate = () => {

        gateKeys.forEach((gateName) => {
            const sanitizedExpName = getMeaningfulExperimentNames(gateName);
            const experimentInitialDetails = allExperiments[sanitizedExpName];
            if (experimentInitialDetails) {
                const experimentData = Statsig.checkGate(gateName);
                dispatch(setExperimentConnect(sanitizedExpName, experimentData));
            }
        });
    };

    React.useEffect(() => {
        if (statsigInitialized && process.env.STATSIG_SECRET_CSR_KEY) {
            getGate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializedTimeStamp]);

};

export default useGate;
