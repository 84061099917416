import { SUPER_APP_SECTION_TYPES } from "../../constants/super-app-constants";

export default (responseList = []) => {
    const resultMap = {};
    const keyList = [];

    if (!responseList || !responseList.length) return null;

    responseList.filter(item => item && item.key && (item.key !== SUPER_APP_SECTION_TYPES.SUPER_WEB_FOOTER_GRID)).forEach(dataNode => {
        const { data, key: sectionKey } = dataNode || {};
        keyList.push({
            key: sectionKey
        });
        if (data && data.content) {
            const isArrayWithLength = Array.isArray(data.content) && data.content.length > 0;
            const isObjectWithKeys = typeof data.content === "object" && !Array.isArray(data.content) && Object.keys(data.content).length > 0;
            if (isArrayWithLength || isObjectWithKeys) {
                const { key } = dataNode || {};
                resultMap[key] = dataNode;
            }
        }

    });

    return { parsedData: resultMap, dataList: keyList };
};
