const routePageMap = {
    "home": "home_page",
    "car-listing": "listings_page",
    "car-details": "car_details_page",
    "car-details-checkout": "checkout",
    "myBookings": "my_bookings",
    "buy-used-cars": "home_page"
};

export default routePageMap;
