import { testimonialParser } from "../../../utils/helpers/testimonial-parser";
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    data: [],
    error: null
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, data: testimonialParser(data), error: null };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [] };
};

export const updateTestimonialSSR = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const HANDLERS = {
    [Types.FETCH_BUYER_TESTIMONIAL_DATA_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_BUYER_TESTIMONIAL_DATA_FAILURE]: fetchDataFailure,
    [Types.UPDATE_TESTIMONIAL_SSR]: updateTestimonialSSR
};

export default createReducer(INITIAL_STATE, HANDLERS);
