import React from "react";
import PropTypes from "prop-types";
import NeedHelpChatIcon from "../../shared/need-help-chat-icon";
import initChatbot from "../../../utils/helpers/init-chatbot";
import ChatWidget from "../chat-widget/component";
import { sendCustomGaEvents } from "../../../tracking";
import { needHelpIconClick } from "./tracking";
import { routeToEventCategoryMapping } from "../../../constants/app-constants";

const ChatIcon = ({
    route,
    iconProperties,
    setChatIconPropertyConnect,
    setModalSourceConnect,
    modalStatus,
    cityName,
    cityId,
    isLoggedIn,
    userId
}) => {
    const contexts = {
        "home": "default",
        "buy-used-cars": "buy-used-cars",
        "car-listing": "buy-used-cars",
        "car-detail": "buy-used-cars-detail",
        "car-details-checkout": "buy-used-cars-checkout"
    };

    const disableRoutes = [
        "buy-used-cars",
        "buy-used-cars-home",
        "car-listing",
        "car-detail",
        "car-details-checkout",
        "home",
        "used-car-loan"
    ];
    const allowedRoutesforFloatingIcon = [
        "faq",
        "why-cars24"
    ];

    const disableWidgetRoutes = [
        "buy-used-cars"
    ];

    const disableChatWidget = disableWidgetRoutes.includes(route.name);

    const disableChatIcon = disableRoutes.includes(route.name);

    const onClick = () => {
        initChatbot({ reset: true, context: contexts[route.name] || "default" });
    };

    const onToggleCallback = () => {
        setChatIconPropertyConnect(route.name, { variation: "static" });
    };
    const handleOnClick = () => {
        sendCustomGaEvents({
            ...needHelpIconClick,
            eventCategory: routeToEventCategoryMapping[route.name],
            dimension112: isLoggedIn ? "yes" : "no",
            dimension140: cityId,
            dimension144: cityName,
            dimension148: userId
        });
        setModalSourceConnect(!modalStatus);
    };
    const getChatIcon = () => {
        if ((route.isUsedCarRoute || allowedRoutesforFloatingIcon.includes(route.name)) && !disableChatWidget) {
            return (
                <ChatWidget onClick={handleOnClick} />
            );
        } else {
            return (
                !disableChatIcon && <NeedHelpChatIcon
                    onToggleCallback={onToggleCallback}
                    onClick={onClick}
                    {...((route && route.name && iconProperties[route.name]) ? iconProperties[route.name] : { hide: true })}
                />
            );
        }
    };

    return (
        <React.Fragment>
            {getChatIcon()}
        </React.Fragment>

    );
};

ChatIcon.propTypes = {
    route: PropTypes.string,
    iconProperties: PropTypes.object,
    setChatIconProperty: PropTypes.func,
    setChatIconPropertyConnect: PropTypes.func,
    setModalSourceConnect: PropTypes.func,
    modalStatus: PropTypes.bool,
    cityName: PropTypes.string,
    cityId: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    userId: PropTypes.string
};

export default ChatIcon;
