import React from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import parseSuperAppListingSection from "../../../utils/helpers/parse-super-app-listing-section";
import { LazyComponent } from "../../shared/lazy-image";
const SuperWebBuyCar = loadable(() => import("../super-web-buy-car"));

const SuperWebBuyCarWrapper = ({
    superApp,
    sectionKey,
    onClickViewAllCars = () => {},
    platformLabel
}) => {
    const { parsedData } = superApp || {};
    const { data, title, description  } = parsedData[sectionKey] || {};
    const { content } = data || {};
    const {tabsData, tabsDisplayList = [] } = parseSuperAppListingSection(content);

    return tabsDisplayList && tabsDisplayList.length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <SuperWebBuyCar
                    title={title}
                    description={description}
                    tabsData={tabsData}
                    tabsDisplayList={tabsDisplayList}
                    onClickViewAllCars={onClickViewAllCars}
                    platformLabel={platformLabel}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment/>);
};

SuperWebBuyCarWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    onClickViewAllCars: PropTypes.func,
    platformLabel: PropTypes.string
};

export default SuperWebBuyCarWrapper;
