export default (dataMap = {}, key) => {
    const {data } = dataMap[key] || {};
    const { content: appBannerContent = []} = data || {};
    const bannerMap = {};
    (appBannerContent || []).forEach((bannerData) => {
        const { bannerId, key: bannerItemKey } = bannerData || {};
        const bannerKey = bannerId || bannerItemKey;
        bannerMap[bannerKey] = bannerData;
    });

    return bannerMap;

};
