/* eslint-disable complexity, max-statements */
import React, { useMemo } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { SwiperSlider } from "../../shared/swiper";
import getFilteredMediaData from "../../../utils/helpers/get-filtered-media-data";
import { MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import { UspCard } from "@cars24/turbo-web/lib/usp-card";
import Gradient from "./images/gradient.svg";
import parseUspList from "../../../utils/helpers/parse-usp-list";
import { LazyComponent } from "../../shared/lazy-image";
import { InView } from "react-intersection-observer";
import { sendCustomGaEvents } from "../../../tracking";
import { uspBannerView, uspBannerClick } from "../../../constants/tracking-constants/super-home-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
const PreApprovedText = loadable(() => import("../../shared/pre-approved-text"));
const SuperWebWhyUsSection = loadable(() => import("../super-web-why-us-section"));

const SuperWebWhyUsWrapper = ({
    superApp,
    sectionKey,
    onClickCustomHandler = () => {},
    preApproved,
    selectedOffer,
    platformLabel,
    cfUserName,
    pincode
}) => {

    const { parsedData } = superApp || {};
    const { title, data: sectionData } = parsedData[sectionKey] || {};

    const onBannerClickHandler = async (params, label) => {
        onClickCustomHandler(params);

        await yieldToMain();
        sendCustomGaEvents({
            ...uspBannerClick,
            eventLabel: `${label} | ${platformLabel}`
        });
    };

    const onChangeViewHandler = (inView, BULabel) => {
        if (inView) {
            sendCustomGaEvents({
                ...uspBannerView,
                eventLabel: `${BULabel} | ${platformLabel}`
            });
        }
    };

    const bannerData = useMemo(() => {
        const { content } = sectionData || {};
        const filteredBannerData = getFilteredMediaData(content, MEDIA_DEVICE_TYPES.DESKTOP);
        // eslint-disable-next-line consistent-return
        const bannersList = filteredBannerData.map((bannerItem, idx) => {

            const {
                media,
                action,
                title: cardTitle,
                tagline,
                buttonText,
                background,
                uspDetails,
                meta: bannerMetaData
            } = bannerItem || {};
            const { url: bannerUrl } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const {
                hasLabelImage,
                btnBackground = "transparent",
                preApprovedEnabled,
                preApprovedBtnLabel,
                BULabel
            } = bannerMetaData || {};
            const uspList = parseUspList(uspDetails, MEDIA_DEVICE_TYPES.DESKTOP);

            let labelImageUrl = "";
            if (hasLabelImage && uspDetails && uspDetails.length) {
                const labelIcon = ((uspDetails || [])[uspDetails.length - 1] || {}).icon || {};
                labelImageUrl = (labelIcon[MEDIA_DEVICE_TYPES.DESKTOP] || {}).url || "";
            }
            const actionData = (action || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { meta } = actionData || {};
            const { flowType } = meta || {};
            const actionNode = {
                [MEDIA_DEVICE_TYPES.DESKTOP]: {
                    ...actionData,
                    actionType: actionData.type
                }
            };

            return (
                <SwiperSlider key={idx}>
                    <InView
                        threshold={1}
                        onChange={(inView) => onChangeViewHandler(inView, BULabel)}

                    >
                        <UspCard

                            imageUrl={bannerUrl}
                            label={cardTitle}
                            heading={preApprovedEnabled && preApproved && cfUserName ? `Congrats, ${cfUserName}` : tagline}
                            btnLabel={preApprovedEnabled && preApproved ? preApprovedBtnLabel || buttonText : buttonText}
                            background={background}
                            uspList={uspList}
                            clickHandler={onBannerClickHandler.bind(null, {action: actionNode, media, flowType }, BULabel)}
                            labelImageUrl={hasLabelImage && labelImageUrl}
                            labelStrip={!hasLabelImage}
                            gradientUrl={Gradient}
                            btnBackground={btnBackground}
                            uspFragment={preApprovedEnabled && preApproved && (<PreApprovedText selectedOffer={selectedOffer}/>)}
                        />
                    </InView>
                </SwiperSlider>
            );

        });

        return bannersList || [];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preApproved, sectionData, selectedOffer, cfUserName, pincode]);

    return bannerData && bannerData.length ? (
        <div styleName={"styles.sectionHeight"} >
            <LazyComponent>
                <SuperWebWhyUsSection
                    title={title}
                    bannerData={bannerData}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebWhyUsWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    preApproved: PropTypes.bool,
    selectedOffer: PropTypes.object,
    onClickCustomHandler: PropTypes.func,
    platformLabel: PropTypes.string,
    cfUserName: PropTypes.string,
    pincode: PropTypes.string
};

export default SuperWebWhyUsWrapper;
