import { createReducer } from "../redux/create-reducer";
import { resettableReducer } from "../redux/resettable-reducer";

const getState = (key, initialState) => {
    if (typeof window === "object" && window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__[key]) {
        return window.__PRELOADED_STATE__[key];
    }
    return initialState;
};

const getReducer = (key, state, handler) => {
    return resettableReducer(key)(createReducer(state, handler));
};

export const getReducerState = (asyncReducers) => {
    const reducers = {};
    Object.keys(asyncReducers).forEach((elem) => {
        const state = getState(elem, asyncReducers[elem].state);
        reducers[elem] = getReducer(elem, state, asyncReducers[elem].handler);
    });
    return reducers;
};
