const configuration = {
    behavior: "smooth",
    block: "start"
};

export default (element, config = configuration) => {
    if (element) {
        element.scrollIntoView(config);
    }
};
