import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ChatIcon from "./component";
import { setChatIconProperty } from "./actions";

const mapStateToProps = ({
    chatIcon: {
        iconProperties
    },
    needHelpPopup: {
        modalStatus
    },
    user: {
        isLoggedIn,
        userId
    },
    location: { pincode, cityInfo: { city_name: cityName, city_id: cityId } }
}) => ({
    iconProperties,
    modalStatus,
    cityName,
    cityId,
    isLoggedIn,
    userId,
    pincode
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setChatIconPropertyConnect: setChatIconProperty
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatIcon));
