import React from "react";
import PropTypes from "prop-types";
import { getLogoURL } from "../../../constants/url-constants";

const Logo = ({ width = "80", height = "39" }) => {
    return (
        <img width={width} height={height} style={{width, height}} src={getLogoURL()} alt="CARS24" />
    );
};

Logo.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
};

export default Logo;
