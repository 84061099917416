import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
const Slider = loadable.lib(() => import("react-slick"), { ssr: true });

const SlickWrapper = React.forwardRef(({ children, ...restProps }, ref) => {
    return (
        <Slider fallback={<React.Fragment />}>
            {({ default: AsyncSlider }) => (<AsyncSlider ref={ref} {...restProps} >
                {children}
            </AsyncSlider>)}
        </Slider>
    );
});

SlickWrapper.propTypes = {
    children: PropTypes.any
};

export default SlickWrapper;
