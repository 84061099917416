import { NUMBER } from "../../constants/app-constants";
import { getDayAbbrMonthYear  } from "./get-day-from-date";

export default (newsList = []) => {
    return (newsList || []).map((newsItem) => {
        const {
            businessType,
            title,
            summary,
            category,
            thumbnail,
            slug,
            readTime,
            authors = [],
            publishedAt
        } = newsItem || {};

        const {
            name: categoryName,
            slug: categorySlug
        } = category || {};

        const {
            url: imageUrl
        } = thumbnail || {};

        const redirectionUrl = `https://cars24.com/news/${categorySlug}/${slug}/`;
        const { name: authorName,
            image: authorImage
        } = authors[0] || {};

        const { url: authorProfileUrl } = authorImage || {};

        return {
            imageUrl,
            businessType,
            tag: categoryName,
            title,
            summary,
            redirectionUrl,
            readTime: `${readTime} ${readTime === NUMBER.ONE ? "MIN" : "MINS"}`,
            authorName,
            publishedAt: getDayAbbrMonthYear(new Date(publishedAt)),
            authorProfileUrl
        };

    });
};
