import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Skeleton from "./skeleton";
import { STROKE_VARIANT } from "./constants";

const HeadingWithStroke = ({
    text,
    color,
    isLoading,
    variant,
    smallFont = false,
    hideStroke = false
}) => {

    const strokeStyle = useMemo(() => {
        if (variant === STROKE_VARIANT.BLUE) {
            return "styles.blueColor";
        }
        return "";
    }, [variant]);

    let heading = (
        <h2 styleName={`styles.heading ${smallFont ? "styles.font22" : ""} ${strokeStyle} ${hideStroke ? "styles.hide" : ""}`} style={{ color }}>
            {text}
        </h2>
    );
    if (isLoading) {
        heading = <Skeleton />;
    }
    return heading;
};

HeadingWithStroke.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    isSuperWebHeading: PropTypes.bool,
    variant: PropTypes.string,
    smallFont: PropTypes.bool,
    hideStroke: PropTypes.bool
};

export default HeadingWithStroke;
