import React, { useMemo } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import { SwiperSlider } from "../../shared/swiper";
import PropTypes from "prop-types";
import getFilteredMediaData from "../../../utils/helpers/get-filtered-media-data";
import { MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import { SocialTestimonialCard } from "@cars24/turbo-web/lib/social-testimonial-card";
import { LazyComponent } from "../../shared/lazy-image";
import { InView } from "react-intersection-observer";
import { sendCustomGaEvents } from "../../../tracking";
import { socialTestimonialViewed } from "../../../constants/tracking-constants/super-home-events";
const SuperWebReviewSection = loadable(() => import("../super-web-review-section"));

const SuperWebReviewWrapper = ({
    superApp,
    sectionKey,
    platformLabel
}) => {
    const { parsedData } = superApp || {};
    const { data: sectionData } = parsedData[sectionKey] || {};

    const testimonialData = useMemo(() => {
        const { content } = sectionData || {};
        const filteredData = getFilteredMediaData(content, MEDIA_DEVICE_TYPES.DESKTOP);
        const testimonialList = filteredData.map((testimonial, idx) => {

            const {
                media,
                title,
                meta: metaData
            } = testimonial || {};
            const { url: profileImageUrl } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { date, review } = metaData || {};

            return (
                <SwiperSlider key={idx}>
                    <SocialTestimonialCard
                        key={idx}
                        profileImageUrl={profileImageUrl}
                        dateValue={date}
                        testimonial={review}
                        profileName={title}
                    />
                </SwiperSlider>
            );

        });

        return testimonialList || [];

    }, [sectionData]);

    const onChangeViewHandler = (inView) => {
        if (inView) {
            sendCustomGaEvents({
                ...socialTestimonialViewed,
                eventLabel: platformLabel
            });
        }
    };

    return testimonialData && testimonialData.length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <InView threshold={0.8} onChange={(inView) => onChangeViewHandler(inView)}>
                    <SuperWebReviewSection
                        testimonialData={testimonialData}
                        platformLabel={platformLabel}
                    />
                </InView>
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebReviewWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    platformLabel: PropTypes.string
};

export default SuperWebReviewWrapper;
