import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SuperWebDiscountSection from "./component";

const mapStateToProps = ({
    superApp,
    user: {
        preApproved
    }
    // config
}) => ({
    superApp,
    preApproved
    // config
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuperWebDiscountSection);
