export const IDENTIFIERS = {
    BUY: "BUY",
    SELL: "SELL",
    LOAN: "LOAN"
};

const MENU_ORDER = [
    IDENTIFIERS.BUY,
    IDENTIFIERS.SELL,
    IDENTIFIERS.LOAN
];

export const ITEM_BLOCKS = {
    [IDENTIFIERS.SELL]: {
        text: "Sell",
        redirectURL: "/sell-used-cars/",
        id: IDENTIFIERS.SELL
    },
    [IDENTIFIERS.BUY]: {
        text: "Buy",
        redirectURL: "/",
        id: IDENTIFIERS.BUY
    },
    [IDENTIFIERS.LOAN]: {
        text: "Finance",
        redirectURL: "/used-car-loan/",
        id: IDENTIFIERS.LOAN
    }
};

const getMenuTabs = (config = {}, defaults = [], showDefault) => {
    const MENU_CONDITIONS = {
        [IDENTIFIERS.BUY]: {
            default: defaults.indexOf(IDENTIFIERS.BUY) >= 0,
            // condition: config.is_buy_used_car,
            condition: true
        },
        [IDENTIFIERS.SELL]: {
            default: defaults.indexOf(IDENTIFIERS.SELL) >= 0,
            condition: true
        },
        [IDENTIFIERS.LOAN]: {
            default: defaults.indexOf(IDENTIFIERS.LOAN) >= 0,
            condition: config.loan_enabled && config.loanFlow
        }
    };

    return MENU_ORDER.map((item) => {
        if (showDefault) {
            return MENU_CONDITIONS[item].default && ITEM_BLOCKS[item];
        } else {
            return (MENU_CONDITIONS[item].condition && !MENU_CONDITIONS[item].default) && ITEM_BLOCKS[item];
        }
    }).filter((item) => item);
};

export default getMenuTabs;

