/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-magic-numbers
export const VALID_MOBILE_NUMBER = new RegExp(/^[6789]\d{9}$/);
export const INVALID_MOBILE_NUMBER = new RegExp(/^([0-9])\1*$/);

const MOBILE_REGEX_AE = new RegExp(/^(?:50|52|54|55|56|58|1|2|3|4|6|7|9|0)\d{7}$/);
//^(?:\0)(?!2)((?:2|3|4|6|7|9|50|52|54|55|56|58)[0-9]{7,})$
//(+971/0971/0/+/971) + (01/02/03/04/06/07/09/050/052/054/055/056/058) + (XXXXXXX i.e. 7 digit) ==> from product

export const mobileNumberValidator = (mobile) => {
    return VALID_MOBILE_NUMBER.test(parseInt(mobile)) &&
    !INVALID_MOBILE_NUMBER.test(parseInt(mobile));
};

export const mobileNumberValidatorAE = (mobile) => {
    if (process.env.HOST_ENV === "QA") {
        return mobileNumberValidator(mobile);
    } else {
        return MOBILE_REGEX_AE.test(parseInt(mobile)) && (mobile.length >= 8 && mobile.length <= 10);
    }

};

export const isMobileEligible = (mobile, lastDigitList) => {
    const lastDigit = mobile % 10;
    return lastDigitList.indexOf(lastDigit) !== -1;
};
