import React, { useEffect } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

// TODO - Refactor this component
const Checkbox = ({
    checkedStatus,
    onClickCallback = () => { },
    id = "checkbox",
    name = "checkbox",
    disabled = false,
    className = "",
    onChange = () => {},
    children = "",
    inputRef,
    semiCheckedStatus = false
}) => {
    const [checked, setChecked] = React.useState(checkedStatus);

    useEffect(() => {
        setChecked(checkedStatus);
    }, [checkedStatus]);

    const onClickHandler = (e) => {
        e.stopPropagation();
        onClickCallback(e);
    };

    const handleChange = () => {
        const value = !checked;
        setChecked(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div styleName={`styles.checkboxHolder ${semiCheckedStatus ? "styles.semiCheck" : ""}`}>
            <input
                ref={inputRef}
                onClick={onClickHandler}
                type="checkbox"
                id={id}
                name={name}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                className={className}
            />
            <label htmlFor={id}>{children}</label>
        </div>
    );
};

Checkbox.propTypes = {
    onClickCallback: PropTypes.func,
    checkedStatus: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    inputRef: PropTypes.object,
    semiCheckedStatus: PropTypes.bool
};

export default Checkbox;
