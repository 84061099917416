import Types from "./types";

const setChatIconProperty = (page, properties) => ({
    type: Types.SET_ICON_PROPERTY,
    page,
    properties
});

export {
    setChatIconProperty
};
