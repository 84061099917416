import React from "react";
import styles from "./styles.css";
import ArrowIcon from "../../shared/arrow";
import CloseButton from "./images/close-icon.svg";
import LazyImage from "../../shared/lazy-image";
import PropTypes from "prop-types";
import RequestCallbackSection from "../../desktop/request-callback-section";

const RequestCallbackModal = ({
    setShowCallBackModal = () => {},
    setModalSourceConnect = () => {},
    isBackBtnVisible = true,
    source = ""
}) => {

    const handleOnClick = () => {
        setShowCallBackModal(false);
        setModalSourceConnect(false);
    };
    const haddleOnClickBackArrow = () => {
        setShowCallBackModal(false);
        setModalSourceConnect(true);
    };

    return (
        <div styleName={"styles.helpModalWrap"}>
            <div styleName={"styles.header"}>
                <h4>
                    {isBackBtnVisible && (
                        <ArrowIcon
                            black={"BlackArrow"}
                            rotateBy={90}
                            onClick={haddleOnClickBackArrow}
                        />
                    )}
                    Request call back
                </h4>
                <LazyImage onClick={handleOnClick} src={CloseButton} alt="close" />
            </div>
            <RequestCallbackSection source={source} />
        </div>
    );
};

RequestCallbackModal.propTypes = {
    isBackBtnVisible: PropTypes.bool,
    setShowCallBackModal: PropTypes.func,
    setModalSourceConnect: PropTypes.func,
    source: PropTypes.string
};

export default RequestCallbackModal;
