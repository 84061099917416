import { LmsService } from "../../../service/lms-service";

const leadpost = (params) => () => {
    return new Promise((resolve, reject) => {
        LmsService.requestCallbackLead(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};
export {
    leadpost
};
