import { appUrl } from "./url-constants";

export const CF_SEO_LINKS = [
    {
        text: "Used car loan",
        url: `${appUrl()}/used-car-loan/`
    },
    {
        text: "Used car loan eligibility",
        url: `${appUrl()}/used-car-loan/eligibility-and-documents/`
    },
    {
        text: "Used car loan interest rates",
        url: `${appUrl()}/used-car-loan/interest-rates-and-charges/`
    },
    {
        text: "Used car EMI calculator",
        url: `${appUrl()}/used-car-loan/emi-calculator/`
    }
];
