import React from "react";
import styles from "./styles.css";
import Errorgraphic from "./images/error-graphic.svg";
import Logo from "../../../components/shared/logo";

const Error = () => {
    return (
        <div styleName={"styles.errorWrap"}>
            <span styleName={"styles.logoImage"}><Logo /></span>
            <h1 styleName={"styles.errorTitle"}>We'll be right back!</h1>
            <p styleName={"styles.errorPara"}>
                Unfortunately, the site is down for a bit of maintenance
                right now. <span>But soon we'll be up and running again.</span>
            </p>
            <img styleName={"styles.graphic"} src={Errorgraphic} />
            <p styleName={"styles.errorLastText"}>Need Help?
                <a href="tel:1800 258 5656"> 1800 258 5656</a> (9 AM to 9 PM)
            </p>
        </div>
    );
};

export default Error;
