export default ({
    haystack,
    needle
}) => {
    const ar = haystack.filter(item => needle.includes(item));
    return {
        filteredList: ar,
        elementExists: !!ar.length
    };
};

export const findSubstringInArrayByRegex = ({
    haystack,
    needle
}) => {
    const ar = haystack.filter(item => needle.match(item));
    return {
        filteredList: ar,
        elementExists: !!ar.length
    };
};
