import React, { useEffect } from "react";

const useManualScrollRestoration = () => {
    useEffect(() => {
        if (window && ("scrollRestoration" in window.history)) {
            window.history.scrollRestoration = "manual";
        }

        return () => {
            if (window && ("scrollRestoration" in window.history)) {
                window.history.scrollRestoration = "auto";
            }
        };
    }, []);
};

export default useManualScrollRestoration;

