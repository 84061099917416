export const CMS_ACTION_TYPES = {
    NAVIGATE: "navigate",
    POPUP: "popup",
    REDIRECT: "redirect"
};

export const CMS_REDIRECT_TYPES = {
    BUTTON: "BUTTON",
    LINK: "LINK"
};
