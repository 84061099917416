import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { TinyText } from "@cars24/turbo-web/lib/tiny-text";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { Chips } from "@cars24/turbo-web/lib/chips/index";
import SuffixIcon from "./suffix-icon";

import { sendCustomGaEvents } from "../../../tracking";
import { trandingSearchesView, trandingSearchesOnClick } from "./tracking";

const TrendingSearchList = ({
    trendingSearch,
    handleTrendingSearchSelect,
    dimensionObject
}) => {

    const { tokens } = useTokens();

    useEffect(() => {
        if (trendingSearch && trendingSearch.length > 0) {
            const trendingSearchValues = (trendingSearch || []).map((i) => i.title).join("|");
            sendCustomGaEvents({
                ...trandingSearchesView,
                eventAction: trendingSearchValues,
                eventLabel: trendingSearch.length,
                ...dimensionObject
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trendingSearch]);

    const handleOnClick = (option, index) => {
        const { title } = option || {};
        handleTrendingSearchSelect(option);
        sendCustomGaEvents({
            ...trandingSearchesOnClick,
            eventAction: `${title} | ${index + 1}`,
            eventLabel: trendingSearch.length,
            ...dimensionObject
        });
    };

    return (
        <div styleName={"styles.searchListWrap"}>
            <TinyText color={tokens.text_default_text_secondary}>Trending Cars</TinyText>

            <ul styleName={"styles.trendingSearch"}>
                {trendingSearch && trendingSearch.length > 0 && trendingSearch.map((option, index) => {
                    const { title } = option;
                    return (
                        <Chips key={index}
                            label={ title}
                            onClick={() => handleOnClick(option, index)}
                            suffixIcon={<SuffixIcon/>}
                            size="small"
                        />
                    );
                })}

            </ul>
        </div>
    );
};
TrendingSearchList.propTypes = {
    dimensionObject: PropTypes.object,
    trendingSearch: PropTypes.array,
    handleTrendingSearchSelect: PropTypes.func
};

export default TrendingSearchList;
