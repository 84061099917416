import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { TRACKER_TYPES } from "./selector";
import { sendCustomGaEvents } from "../../../tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { InView } from "react-intersection-observer";

const GenericBrandBanner = ({ config = {}, type = "", fetchpriority = "", pageType = "", position = "", source = "" }) => {
    const { trackers = [], banners = {} } = config || {};
    const bannerData = banners[type] || {};
    const visibilityReported = useRef(false);

    useEffect(() => {
        sendCustomGaEvents({
            eventLabel: `nissan | ${pageType}_${source}_${position} | ${type}`,
            eventName: "ad_slot_load",
            eventAction: "ad_slot_loaded",
            eventCategory: pageType
        });
    }, [pageType, position, source, type]);

    const handleVisibilityChange = (inView) => {
        if (inView && !visibilityReported.current) {
            visibilityReported.current = true;
            sendCustomGaEvents({
                eventLabel: `nissan | ${pageType}_${source}_${position} | ${type}`,
                eventName: "ad_viewed",
                eventAction: "ad_viewed",
                eventCategory: pageType
            });
        } else if (!inView) {
            visibilityReported.current = false;
        }
    };

    if (!bannerData && !bannerData.image) return null;

    const { url, alt = "banner", redirectionUrl = "" } = bannerData.image || {};
    const handleBannerClick = async () => {
        await yieldToMain();
        sendCustomGaEvents({
            eventLabel: `nissan | ${pageType} | ${type}`,
            eventName: "ad_clicked",
            eventAction: "ad_clicked",
            eventCategory: pageType
        });
    };

    return (
        <>
            <Helmet>
                {trackers.map((tracker) => {
                    if (tracker.type === TRACKER_TYPES.SCRIPT) {
                        return (
                            <script key={tracker.id} src={tracker.src} id={tracker.id} async {...tracker.attributes} />
                        );
                    }
                    return null;
                })}
            </Helmet>

            {trackers.map((tracker) => {
                if (tracker.type === TRACKER_TYPES.IMG) {
                    return (
                        <img
                            key={tracker.id}
                            src={tracker.src}
                            alt={tracker.alt}
                            style={{ display: "none" }}
                            {...tracker.attributes}
                        />
                    );
                }
                if (tracker.type === TRACKER_TYPES.IFRAME) {
                    return (
                        <iframe
                            key={tracker.id}
                            src={tracker.src}
                            id={tracker.id}
                            style={{ display: "none" }}
                            title={`Tracker iframe ${tracker.id}`}
                            {...tracker.attributes}
                        />
                    );
                }
                return null;
            })}

            <InView as="div" threshold={0.5} onChange={
                (inView) => handleVisibilityChange(inView)}>
                {({ ref }) => (
                    <a
                        ref={ref}
                        href={redirectionUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleBannerClick}
                    >
                        <img
                            src={url}
                            alt={alt || "Banner"}
                            style={{ cursor: "pointer" }}
                            fetchpriority={fetchpriority}
                        />
                    </a>
                )}
            </InView>
        </>
    );
};

GenericBrandBanner.propTypes = {
    config: PropTypes.object,
    type: PropTypes.string,
    fetchpriority: PropTypes.string,
    pageType: PropTypes.string,
    position: PropTypes.string,
    source: PropTypes.string
};

export default GenericBrandBanner;
