import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import SupportWidget from "./component";
import { setModalSource } from "../help-support-modal/action";

const mapStateToProps = ({
    needHelpPopup: {
        modalStatus
    }
}) => ({
    modalStatus
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setModalSourceConnect: setModalSource
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportWidget));
