import React from "react";
import PropTypes from "prop-types";
import googlePlay from "./images/google-play.svg";
import twitterBlue from "./images/twitter-blue-1.svg";
import { cdnImageUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import LazyImage from "../lazy-image";

const SocialIcon = ({ name, imageUrl, ...rest }) => {

    const imageMap = {
        googlePlay,
        appStore: cdnImageUrl("c2b-react-production/app-store-icon.png", imageTypes.appstoreIcon),
        twitterBlue
    };

    const getURL = () => imageMap[name] || imageUrl;

    return <LazyImage className={"img-fluid"} src={getURL()} alt={name} {...rest} />;
};

SocialIcon.propTypes = {
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string
};

export default SocialIcon;
