export const loginScreenClosed = {
    event: "login_screen_closed",
    eventName: "new_login",
    eventAction: "login | login_screen_closed"
};

export const loginScreenOpened = {
    event: "login_screen_opened",
    eventName: "new_login",
    eventAction: "login | login_screen_opened"
};

export const loginOtpRequested = {
    event: "otp_requested",
    eventName: "new_login",
    eventAction: "login | otp_requested"
};

export const resendOtpClicked = {
    event: "resend_otp_clicked",
    eventName: "new_login",
    eventAction: "login | resend_otp_clicked"
};

export const phoneNumberEdited = {
    event: "phone_number_edited",
    eventName: "new_login",
    eventAction: "login | phone_number_edited"
};

export const phoneNumberEntered = {
    event: "phone_number_entered",
    eventName: "new_login",
    eventAction: "login | phone_number_entered"
};

export const phoneNumberAttempted = {
    event: "phone_number_attempted",
    eventName: "new_login",
    eventAction: "login | phone_number_attempted"
};

export const whatsappCheckboxClicked = {
    event: "whatsapp_checkbox_clicked",
    eventName: "new_login",
    eventAction: "login | whatsapp_checkbox_clicked",
    eventLabel: "check/uncheck"
};

export const otpIncorrect = {
    event: "otp_incorrect",
    eventName: "new_login",
    eventAction: "login | otp_incorrect"
};

export const optLoginSuccess = {
    event: "otp_login_success",
    eventName: "new_login",
    eventAction: "login | otp_login_success"
};

export const ga4Signin = {
    event: "signin"
};

