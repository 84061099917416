import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export default function withDesktopLogin(Component) {
    const AuthenticatedComponent = (props) => {
        const [showLogin, setShowLogin] = useState(false);
        return (
            <Component
                {...props}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
        );
    };

    AuthenticatedComponent.propTypes = {
        isLoggedIn: PropTypes.bool
    };

    const mapStateToProps = ({ user: { isLoggedIn } }) => {
        return {
            isLoggedIn
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(
        AuthenticatedComponent
    );
}
