export const superHomeViewed = {
    event: "super_home_page_viewed",
    eventAction: "super_home_page_viewed",
    eventCategory: "super_home_page"
};

export const bottomTabClicked = {
    event: "tab_clicked",
    eventAction: "tab_clicked",
    eventCategory: "super_home_page"
};

export const businessClicked = {
    event: "top_businesses_clicked",
    eventAction: "top_businesses_clicked",
    eventCategory: "super_home_page"
};

export const offerClicked = {
    event: "offers_clicked",
    eventAction: "offers_clicked",
    eventCategory: "super_home_page"
};

export const offerScrolled = {
    event: "offers_scrolled",
    eventAction: "offers_scrolled",
    eventCategory: "super_home_page"
};

export const offerViewed = {
    event: "offers_viewed",
    eventAction: "offers_viewed",
    eventCategory: "super_home_page"
};

export const carCardClicked = {
    event: "car_clicked",
    eventAction: "car_clicked",
    eventCategory: "super_home_page"
};

export const uspBannerClick = {
    event: "usp_clicked",
    eventAction: "usp_clicked",
    eventCategory: "super_home_page"
};

export const uspBannerView = {
    event: "usp_viewed",
    eventAction: "usp_viewed",
    eventCategory: "super_home_page"
};

export const searchClicked = {
    event: "search_clicked",
    eventAction: "search_clicked",
    eventCategory: "super_home_page"
};

export const menuClick = {
    event: "hamburger_clicked",
    eventAction: "hamburger_clicked",
    eventCategory: "super_home_page"
};

export const heroBannerView = {
    event: "hero_banner_viewed",
    eventAction: "hero_banner_viewed",
    eventCategory: "super_home_page"
};

export const heroBannerClick = {
    event: "hero_banner_click",
    eventAction: "hero_banner_click",
    eventCategory: "super_home_page"
};

export const downloadBannerClick = {
    event: "download_banner_clicked",
    eventAction: "download_banner_clicked",
    eventCategory: "super_home_page"
};

export const downloadBannerView = {
    event: "download_banner_viewed",
    eventAction: "download_banner_viewed",
    eventCategory: "super_home_page"
};

export const videoClick = {
    event: "video_testimonial_clicked",
    eventAction: "video_testimonial_clicked",
    eventCategory: "super_home_page"
};

export const videoViewed = {
    event: "video_testimonial_viewed",
    eventAction: "video_testimonial_viewed",
    eventCategory: "super_home_page"
};

export const ourServiceClick = {
    event: "our_services_clicked",
    eventAction: "our_services_clicked",
    eventCategory: "super_home_page"
};

export const ourServiceViewed = {
    event: "our_services_viewed",
    eventAction: "our_services_viewed",
    eventCategory: "super_home_page"
};

export const newsViewed = {
    event: "news_viewed",
    eventAction: "news_viewed",
    eventCategory: "super_home_page"
};

export const newsClicked = {
    event: "news_clicked",
    eventAction: "news_clicked",
    eventCategory: "super_home_page"
};

export const socialTestimonialViewed = {
    event: "social_testimonials_viewed",
    eventAction: "social_testimonials_viewed",
    eventCategory: "super_home_page"
};

export const needHelpClicked = {
    event: "need_help_clicked",
    eventAction: "need_help_clicked",
    eventCategory: "super_home_page"
};

export const seoLinkClicked = {
    event: "seo_link_clicked",
    eventAction: "seo_link_clicked",
    eventCategory: "super_home_page"
};

export const categoryFilterOneClick = {
    event: "car_category_filter_1_clicked",
    eventAction: "car_category_filter_1_clicked",
    eventCategory: "super_home_page"
};

export const categoryFilterTwoClick = {
    event: "car_category_filter_2_clicked",
    eventAction: "car_category_filter_2_clicked",
    eventCategory: "super_home_page"
};
