import React, { memo } from "react";
import ReactModal from "react-modal";
import styles from "./styles.css";
import PropTypes from "prop-types";

const Modal = ({ children,
    isOpen,
    close,
    contentClass = "",
    overlayClass = "overlayClass",
    onAfterOpen,
    hideOverlay = false,
    hideContent = false
}) =>
    (
        <ReactModal
            isOpen={isOpen}
            contentLabel="a"
            onRequestClose={close}
            shouldCloseOnOverlayClick={true}
            overlayClassName={`${overlayClass} ${hideOverlay ? `hideOverlay` : ``}`}
            className={`contentClass ${contentClass} ${hideContent ? `hideContent` : ``} `}
            onAfterOpen={onAfterOpen}
            ariaHideApp={false}
        >
            {children}
        </ReactModal>);

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    contentClass: PropTypes.string,
    overlayClass: PropTypes.string,
    onRequestClose: PropTypes.func,
    onAfterOpen: PropTypes.func,
    hideOverlay: PropTypes.bool,
    hideContent: PropTypes.bool
};

export default memo(Modal);
