const buyOptionClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | buy_used_car"
};

const sellOptionClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | sell_used_car"
};

const resourcesOptionClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | resources"
};

const onClickPopularbrand = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "buy_a_car | popular_brands | select"
};

const onClickPopularModels = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "buy_a_car | popular_models | select"
};

const onClickBodyType = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "buy_a_car | cars_by_bodytype | select"
};

const onClickFuleType = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "buy_a_car | cars_by_fuel | select"
};

const onClickTransmissionType = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "buy_a_car | cars_by_transmission | select"
};

const onClickBudgetType = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "buy_a_car | cars_by_budget | select"
};

const rCTransferOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | rc_transfer_status"
};

const becomeOurPartnerOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | become_our_partner"
};

const faqOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | faq"
};

export const gaEventsMap = {
    buy: buyOptionClick,
    sell: sellOptionClick,
    res: resourcesOptionClick,
    onClickPopularbrand,
    onClickPopularModels,
    onClickBodyType,
    onClickFuleType,
    onClickTransmissionType,
    onClickBudgetType,
    rCTransferOnClick,
    becomeOurPartnerOnClick,
    faqOnClick
};

export const onClickLocationPicker = {
    event: "new_menu",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "change_location | select"
};

export const carFinanceClick = {
    event: "top_nav_events",
    eventName: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "car_finance | select"
};

export const wishlistHeaderClick = {
    event: "top_nav_events",
    eventName: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage"
};

export const AccountTabLogin = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | login"
};

export const appoinmentOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | appointments"
};

export const bookingOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | bookings"
};

export const onClickLogo = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "logo | select",
    eventLabel: "logo"
};

export const editAccount = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | edit_profile"
};

export const sellUsedOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | sell_used_car",
    eventLabel: "dropdown"
};

export const resOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | resources",
    eventLabel: "dropdown"
};

export const sellOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "sell_a_car | select"
};

export const logOutOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "my_account | logout"
};

export const hamburgerChallan = {
    event: "hamburger_challan",
    eventCategory: "hamburger_menu",
    eventAction: "View challan clicked"
};

export const hamburgerCarServicing = {
    event: "fourdoor_clicked",
    eventCategory: "fourdoor",
    eventAction: "fourdoor_clicked",
    eventLabel: "desktop"
};

export const hamburgerCarScrap = {
    event: "car_scrap",
    eventCategory: "hamburger_menu",
    eventAction: "car_scrap_clicked",
    eventLabel: "carScrap"
};

export const hamburgerCheckVehicleDetails = {
    event: "check_vehicle_details",
    eventCategory: "hamburger_menu",
    eventAction: "check_vehicle_details_clicked",
    eventLabel: "checkVehicleDetails"
};

export const myOrderClicked = {
    event: "my_order_clicked",
    eventCategory: "my_order_section",
    eventAction: "my_order_clicked"
};
