import React from "react";
import loadable from "@loadable/component";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { DOWNLOAD_BANNER_TYPES, MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import { LazyComponent } from "../../shared/lazy-image";
import { InView } from "react-intersection-observer";
import { sendCustomGaEvents } from "../../../tracking";
import { downloadBannerView } from "../../../constants/tracking-constants/super-home-events";
const SuperWebGetAppBanner = loadable(() => import("../super-web-get-app-banner"));

const SuperWebGetAppBannerWrapper = ({
    superApp,
    platformLabel,
    onClickCustomHandler = () => {}
}) => {

    const { getAppInlineBannerData } = superApp || {};
    const { media } = getAppInlineBannerData || {};
    const { url: mediaUrl } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};

    const onChangeViewHandler = (inView) => {
        if (inView) {
            sendCustomGaEvents({
                ...downloadBannerView,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.INLINE} | ${platformLabel}`
            });
        }
    };

    return mediaUrl ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <InView
                    threshold={0.8}
                    onChange={(inView) => onChangeViewHandler(inView)}>
                    <SuperWebGetAppBanner
                        onClickCustomHandler={onClickCustomHandler}
                    />
                </InView>
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebGetAppBannerWrapper.propTypes = {
    superApp: PropTypes.object,
    onClickCustomHandler: PropTypes.func,
    platformLabel: PropTypes.string
};

export default SuperWebGetAppBannerWrapper;
