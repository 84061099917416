export const noAutoSuggestions = {
    event: "search_page",
    eventCategory: "type_no_suggestions"
};

export const typeInitiated = {
    event: "search_page",
    eventCategory: "type_initiate"
};

export const autoCompleteSuggestionsOnClick = {
    event: "search_page",
    eventCategory: "type_autocomplete"
};

export const autoCompleteSuggestionsOnClickEnter = {
    event: "search_page",
    eventCategory: "type_autocomplete_enter"
};

export const noAutoSuggestionsOnClick = {
    event: "search_page",
    eventCategory: "type_no_autocomplete_enter"
};

export const searchBarOnFocus = {
    event: "Search",
    eventAction: "search_bar_clicked"
};

export const ga4CarSearchEvent = {
    event: "search"
};

export const clearEcommerce = {
    ecommerce: null
};
export const semiAutoCompleteSuggestionsOnClick =  {
    event: "search_page",
    eventCategory: "type_semi_autocomplete"
};
export const semiAutoCompleteSuggestionsOnClickEnter = {
    event: "search_page",
    eventCategory: "type_semi_autocomplete_enter"
};
