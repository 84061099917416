import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setModalSource } from "./action";
import HelpSupportModal from "./component";

const mapStateToProps = ({
    needHelpPopup: {
        modalStatus
    },
    user: {
        isLoggedIn,
        userId
    },
    location: {cityInfo: { city_name: cityName, city_id: cityId } }

}) => ({
    modalStatus,
    isLoggedIn,
    cityId,
    cityName,
    userId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setModalSourceConnect: setModalSource
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpSupportModal));
