/* eslint-disable no-magic-numbers */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const NeedHelpChatIcon = ({
    variation = "static",
    onClick = () => {},
    hide = false,
    bottom = 105,
    right = 20,
    onToggleCallback = () => {}
}) => {
    const variations = {
        static: ["styles.chatbot" ],
        collapsible: ["styles.chatbot", "styles.mobileDefaultArrow"]
    };

    const [buttonStyles, setButtonStyles] = useState(variations[variation]);

    useEffect(() => {
        setButtonStyles(variations[variation]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variation]);

    const onClickButton = () => {
        // Converts collapsible button into regular one
        if (variation === "collapsible" && buttonStyles.length === 2) {
            onToggleCallback();
        }
        // Only execute supplied onClick when button is in static state
        if (buttonStyles.length === 1) {
            onClick();
        }
    };

    return (
        hide ? <React.Fragment /> :
            <div
                style={{ right, bottom }}
                onClick={onClickButton}
                styleName={buttonStyles.join(" ")}>
                <span>Need Help?</span>
            </div>
    );
};

NeedHelpChatIcon.propTypes = {
    variation: PropTypes.string,
    onClick: PropTypes.func,
    hide: PropTypes.bool,
    bottom: PropTypes.number,
    right: PropTypes.number,
    onToggleCallback: PropTypes.func
};

export default NeedHelpChatIcon;
