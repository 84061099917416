import React, { useState } from "react";
import { NUMBER } from "../constants/app-constants";

export default function useScrollToggle(options = {}) {
    const { threshold = -NUMBER.HUNDRED, ref } = options;
    const [trigger, setTrigger] = useState(false);
    const handleScroll = () => {
        if (ref.current) {
            setTrigger(ref.current.getBoundingClientRect().top <= threshold);
        }
    };
    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return trigger;
}
