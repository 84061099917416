// import Types from "./types";
import { AuthService } from "../../../service/auth-service";
import { LeadServiceJava } from "../../../service/lead-service-java-api";

const sendOTP = (mobile) => () => {
    return new Promise((resolve, reject) => {
        AuthService.sendOTP(mobile)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const verifyOtp = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.verifyOTP(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const login = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.login(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const checkLeadPhoneNumber = (data) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.checkLeadPhoneNumber(data)
            .then((response) => {
                resolve(response.data.detail);
            }).catch((error) => {
                reject(error);
            });
    });
};

const sendOtpViaLeadToken = (token) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.sendOtpViaLeadToken(token)
            .then((response) => {
                resolve(response.data.detail);
            }).catch((error) => {
                reject(error);
            });
    });
};

const verifyOtpViaLeadToken = (data) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.verifyOtpViaLeadToken(data)
            .then((response) => {
                resolve(response.data.detail);
            }).catch((error) => {
                reject(error);
            });
    });
};

export {
    sendOTP,
    verifyOtp,
    login,
    checkLeadPhoneNumber,
    sendOtpViaLeadToken,
    verifyOtpViaLeadToken
};
