import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProfileDetails, saveUserConsent, saveUserCibilConsent } from "../../configuration/actions";
import { login, sendOTP, verifyOtp } from "./actions";
import LoginSignup from "./component";

const mapStateToProps = ({
    user: { mobile },
    location: {
        cityInfo
    }
}) => ({
    mobile,
    cityInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    sendOTPConnect: sendOTP,
    verifyOtpConnect: verifyOtp,
    saveUserConsentConnect: saveUserConsent,
    loginConnect: login,
    getProfileDetailsConnect: getProfileDetails,
    saveUserCibilConsentConnect: saveUserCibilConsent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignup);
