export default (contentList = [], deviceType) => {
    const result = {};
    (contentList || []).forEach((contentItem) => {
        const {
            media,
            action,
            key
        } = contentItem || {};

        const {
            url: imageUrl
        } = (media || {})[deviceType] || {};

        const {
            url: redirectUrl
        } = (action || {})[deviceType] || {};

        result[key] = {
            imageUrl,
            action,
            redirectUrl
        };
    });

    return result || {};
};
