/* eslint-disable max-params */
/* eslint-disable complexity */
import { findByKey } from "./find-by-key";
import { setItem } from "./storage-crud";
import saveCookie from "./save-cookie";
import sanitizeItemsToLowercase from "./sanitize-items-to-lowercase";

const CITY_ID = "city_id";
const CITY_SLUG = "city_slug";
const CITY_KEY = "selectedCity";

// eslint-disable-next-line max-params
export default (cities, city, action, isMobile = false) => {
    //need to remove this tostring from city id once the change is implemented

    const currentCity = findByKey(
        cities,
        isNaN(city) ? CITY_SLUG : CITY_ID,
        city.toString(),
        (key, value) => sanitizeItemsToLowercase(
            (key || "").toString()
        ) === sanitizeItemsToLowercase((value).toString())
    );

    const cityDetails = {
        id: currentCity.city_id,
        name: currentCity.city_name,
        image: isMobile ? currentCity.background_image_mobile : currentCity.background_image,
        url: currentCity.is_buy_used_car ? currentCity.buy_used_car_url : null,
        viewAllCarsUrl: currentCity.is_buy_used_car ? currentCity.buy_used_car_url_sec_cta : null,
        slug: currentCity.city_slug,
        isVirtualCity: !!currentCity.is_virtual_city,
        carServiceCity: currentCity.car_service_city ? currentCity.car_service_city : false,
        isB2CCity: !!((currentCity.c2cConfig || {}).configurations || {}).isB2CEnabled
    };
    if (currentCity.is_buy_used_car) {
        const c2cCity = (currentCity.city_name || "").trim().toLowerCase().replace(" ", "-");
        saveCookie("c24-city", c2cCity);
    }
    setItem(cityDetails, CITY_KEY);
    if (!currentCity.is_virtual_city) {
        saveCookie("user_selected_city", currentCity.city_id || "");
    }
    action(cityDetails.id,
        cityDetails.name,
        cityDetails.image,
        cityDetails.url,
        cityDetails.viewAllCarsUrl,
        cityDetails.slug,
        cityDetails.isVirtualCity,
        cityDetails.carServiceCity,
        cityDetails.isB2CCity
    );
};

export const updateCurrentCityWithPicker = (currentCity, action, isMobile = false, pincode) => {
    const cityDetails = {
        id: currentCity.city_id,
        name: currentCity.city_name,
        image: isMobile ? currentCity.background_image_mobile : currentCity.background_image,
        url: currentCity.is_buy_used_car ? currentCity.buy_used_car_url : null,
        viewAllCarsUrl: currentCity.is_buy_used_car ? currentCity.buy_used_car_url_sec_cta : null,
        slug: currentCity.city_slug,
        isVirtualCity: !!currentCity.is_virtual_city,
        carServiceCity: currentCity.car_service_city ? currentCity.car_service_city : false,
        isB2CCity: !!((currentCity.c2cConfig || {}).configurations || {}).isB2CEnabled,
        isGroupCity: !!currentCity.group_city,
        primaryGroupCity: currentCity.primaryGroupCity || null
    };
    if (currentCity.is_buy_used_car) {
        const c2cCity = (currentCity.city_name || "").trim().toLowerCase().replace(" ", "-");
        saveCookie("c24-city", c2cCity);
    }
    setItem(cityDetails, CITY_KEY);
    if (!currentCity.is_virtual_city) {
        saveCookie("user_selected_city", currentCity.city_id || "");
    }
    if (pincode) {
        saveCookie("pincode", pincode);
    }
    action(cityDetails.id,
        cityDetails.name,
        cityDetails.image,
        cityDetails.url,
        cityDetails.viewAllCarsUrl,
        cityDetails.slug,
        cityDetails.isVirtualCity,
        cityDetails.carServiceCity,
        cityDetails.isB2CCity,
        cityDetails.isGroupCity,
        cityDetails.primaryGroupCity
    );
};
