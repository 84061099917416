import React from "react";
import styles from "./styles.css";
import BrandIcon from "../icon-brand";
import PropTypes from "prop-types";

const SuperLogoCard = ({
    image,
    altText,
    onClick,
    selected,
    landingLeadForm = false,
    cardOrderStyling = {},
    isLazyLoad = true
}) => {

    return (
        <div
            styleName={`styles.card ${landingLeadForm ? "styles.landingLeadFormCard" : ""} ${selected ? "styles.active" : ""}`}
            className={`text-center ${selected ? "active" : ""}`}
            onClick={onClick}
            style={cardOrderStyling} >
            <BrandIcon image={image} altText={altText} isLazyLoad={isLazyLoad} />
        </div>

    );
};

SuperLogoCard.propTypes = {
    image: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    landingLeadForm: PropTypes.bool,
    cardOrderStyling: PropTypes.object,
    isLazyLoad: PropTypes.bool
};

export default SuperLogoCard;
