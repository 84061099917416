import  Types from "./types";

const setModalSource = (modalStatus) => ({
    type: Types.SET_MODAL_SOURCE,
    modalStatus
});

export {
    setModalSource
};
