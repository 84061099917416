import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const InputTextarea = ({
    placeholder = "Start typing...",
    onChange,
    onBlurCallBack = () => {},
    ...rest
}) => {

    const textAreaRef = useRef();

    const onChangeHandler = (e) => {
        if (textAreaRef.current.scrollTop !== 0) {
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight  }px`;
        }
        onChange(e);
    };
    const onBlur = (e) => {
        onBlurCallBack(e.target.value);
    };

    return (
        <div styleName={"styles.inputShift"}>
            <textarea
                id= "textarea"
                ref={textAreaRef}
                className="form-control"
                placeholder={placeholder}
                onChange={onChangeHandler}
                onBlur={onBlur}
                {...rest}
            />

        </div>
    );
};

InputTextarea.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.object,
    onBlurCallBack: PropTypes.func
};

export default InputTextarea;
