export const hamburgerChallan = {
    event: "hamburger_challan",
    eventCategory: "hamburger_menu",
    eventAction: "View challan clicked"
};

export const hamburgerServiceHistory = {
    event: "hamburger_service_history",
    eventCategory: "hamburger_menu",
    eventAction: "service_history_check"
};

export const hamburgerCarServicing = {
    event: "fourdoor_clicked",
    eventCategory: "fourdoor",
    eventAction: "fourdoor_clicked",
    eventLabel: "msite"
};

export const hamburgerCarScrap = {
    event: "car_scrap",
    eventCategory: "hamburger_menu",
    eventAction: "car_scrap_clicked",
    eventLabel: "carScrap"
};

export const hamburgerCheckVehicleDetails = {
    event: "check_vehicle_details",
    eventCategory: "hamburger_menu",
    eventAction: "check_vehicle_details_clicked",
    eventLabel: "checkVehicleDetails"
};
