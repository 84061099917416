export const LOCATION_PAGE_TYPE = {
    "buy-used-cars": "Buy_used_cars_B2C_Homepage",
    "car-listing": "Buy_used_cars_B2C_Car_Listing_Page",
    "buy-used-cars-home": "Buy_used_cars_B2C_Buy_Used_Car",
    "car-detail": "Buy_used_cars_B2C_Car_Details_Page"
};

export const presetCity = ["/sell-used-cars", "/sell-used-cars/", "/used-car-valuation", "/used-car-valuation/", "/about-us", "/about-us/"];

export const PREVENT_AUTO_SELECT_CITY = [
    "/sell-used-cars/",
    "/sell-used-cars",
    "/sell-marketing",
    "/sell-marketing/"
];
