import React, { useMemo } from "react";
import styles from "./styles.css";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import getFilteredMediaData from "../../../utils/helpers/get-filtered-media-data";
import { MEDIA_DEVICE_TYPES } from "../../../constants/super-app-constants";
import { CompactCard } from "@cars24/turbo-web/lib/compact-card";
import Arrow from "./images/arrow.svg";
import { LazyComponent } from "../../shared/lazy-image";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { sendCustomGaEvents } from "../../../tracking";
import { needHelpClicked } from "../../../constants/tracking-constants/super-home-events";
const SuperWebNeedHelpSection = loadable(() => import("../super-web-need-help-section"));

const SuperWebNeedHelpWrapper = ({
    superApp,
    sectionKey,
    onClickCustomHandler = () => {},
    platformLabel
}) => {

    const { parsedData } = superApp || {};
    const { title, data: sectionData } = parsedData[sectionKey] || {};

    const onClickHandler = async (params, label) => {
        onClickCustomHandler(params);

        await yieldToMain();
        sendCustomGaEvents({
            ...needHelpClicked,
            eventLabel: `${label} | ${platformLabel}`
        });
    };

    const cardsData = useMemo(() => {
        const { content } = sectionData || {};
        const filteredBannerData = getFilteredMediaData(content, MEDIA_DEVICE_TYPES.DESKTOP);
        // eslint-disable-next-line consistent-return
        const bannersList = filteredBannerData.map((bannerItem) => {

            const { key, media, action, meta: cardMetaData } = bannerItem || {};
            const { url: bannerUrl } = (media || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { heading, description} = cardMetaData || {};
            const actionData = (action || {})[MEDIA_DEVICE_TYPES.DESKTOP] || {};
            const { meta: actionMeta} = actionData || {};
            const { flowType } = actionMeta || {};

            const actionNode = {
                [MEDIA_DEVICE_TYPES.DESKTOP]: {
                    ...actionData,
                    actionType: actionData.type
                }
            };

            return (
                <div key={key} styleName={"styles.containerItem"}>
                    <CompactCard
                        imageUrl={bannerUrl}
                        heading={heading}
                        subText={description}
                        clickHandler={onClickHandler.bind(null, {action: actionNode, media, flowType}, heading)}
                        iconUrl={Arrow}
                    />
                </div>
            );

        });

        return bannersList || [];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionData]);

    return cardsData && cardsData.length ? (
        <div styleName={"styles.sectionHeight"}>
            <LazyComponent>
                <SuperWebNeedHelpSection
                    title={title}
                    cardsData={cardsData}
                />
            </LazyComponent>
        </div>
    ) : (<React.Fragment />);
};

SuperWebNeedHelpWrapper.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    onClickCustomHandler: PropTypes.func,
    platformLabel: PropTypes.string
};

export default SuperWebNeedHelpWrapper;
